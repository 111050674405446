import React from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  Icon,
} from 'semantic-ui-react';

class AccordionItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = { active: props.active };

    this.handleAccordionClick = this.handleAccordionClick.bind(this);
  }

  handleAccordionClick() {
    const active = !this.state.active;

    this.setState({ active });
  };

  render () {
    const { active } = this.state;
    const { title, children } = this.props;

    return (
      <div className="content tw-p-0">
        <Accordion.Title active={active} onClick={this.handleAccordionClick}>
          <Icon name='dropdown' />
          {title}
        </Accordion.Title>

        <Accordion.Content active={active} className="tw-p-4">
          {children}
        </Accordion.Content>
      </div>
    );
  }
}

AccordionItem.defaultProps = {
  active: false
}

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired
};

export default AccordionItem;
