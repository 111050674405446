import React from 'react';
import { Modal } from 'semantic-ui-react';

function CustomTriggerTextModal({
  customTrigger,
  headerText,
  mainText,
  modalHeader,
}) {
  return (
    <Modal trigger={customTrigger} closeIcon>
      {modalHeader || <Modal.Header>{headerText}</Modal.Header>}
      <Modal.Content id="modal_content" className="scrolling content">
        <Modal.Description>
          <div>{mainText}</div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default CustomTriggerTextModal;
