import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from 'semantic-ui-react';
import SelectedProperty from '../SelectedProperty';

export function Pane({
  bids,
  bidGroupId,
  handleRemoveBid,
}) {
  return (
    <Tab.Pane
      attached={false}
      className="basic tw-px-0 bid_search_footer__scrolled_content"
    >
      {
        bids.map(({ id, property }) => (
          <SelectedProperty
            key={id}
            bidId={id}
            bidGroupId={bidGroupId}
            propertyImg={property.image}
            fallbackImage={property.fallbackImage}
            propertyName={property.name}
            propertyId={property.id}
            handleRemoveBid={handleRemoveBid}
          />
        ))
      }
    </Tab.Pane>
  );
}

Pane.defaultProps = {};

Pane.propTypes = {
  bids: PropTypes.arrayOf(
    PropTypes.shape(),
  ).isRequired,
  bidGroupId: PropTypes.number.isRequired,
  handleRemoveBid: PropTypes.func.isRequired,
};
