import React from 'react';
import PropTypes from 'prop-types';
import { orgEventAdminMenuPath } from '../modules/pathHelpers';

class LayoutContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { menu: null, loading: null, error: null };
  }

  componentDidMount() {
    const { orgId, eventId } = this.props;

    const options = {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].content,
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };

    fetch(orgEventAdminMenuPath({ orgId, eventId }), options)
      .then(
        (res) => {
          this.setState({ loading: true });
          return res.json();
        },
      )
      .then(
        (result) => {
          this.setState({
            loading: false,
            menu: result.menu,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            loading: false,
            error,
          });
        },
      );
  }

  render() {
    const { children } = this.props;
    const { menu, loading, error } = this.state;

    return children({ menu, loading, error });
  }
}

LayoutContainer.propTypes = {
  children: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
};

export default LayoutContainer;
