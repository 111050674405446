import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '../../../../../../src/custom/Internationalization';

function AddedActions({
  propertyId,
  handleRemoveBid,
}) {
  return (
    <div className="bid_property_card__added_area">
      <div className="checked_icon" />
      <p className="bid_property_card__added_title">
        {i18n.t('hotel_card.labels.added_to_list')}
      </p>
      <span
        className="bid_property_card__added_link"
        onClick={() => {
          handleRemoveBid({
            propertyId,
            bidId: null,
          });
        }}
      >
        {i18n.t('actions.remove')}
      </span>
    </div>
  );
}

AddedActions.propTypes = {
  propertyId: PropTypes.number.isRequired,
  handleRemoveBid: PropTypes.func.isRequired,
};

export default AddedActions;
