import React from 'react';
import { i18n } from '../../../../src/custom/Internationalization';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    window.Rollbar.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>{i18n.t('errors.notices.generic_Failure')}</h1>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
