import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '../../src/custom/Internationalization';

import HeroSection from '../Common/HeroSection/HeroSection';
import SettingsPanel from '../Common/SettingsPanel/SettingsPanel';
import Loader from './components/Loader';
import AdditionalFilters from './components/AdditionalFilters';
import SearchPropertiesResult from './components/SearchPropertiesResult';
import EventHeader from './components/EventHeader';
import SearchForm from './components/SimpleSearchForm';

import InstantBookingContainer from './containers/InstantBookingContainer';

import HeroSectionContainer from './containers/HeroSectionContainer';
import EventHeaderSectionContainer from './containers/EventHeaderSectionContainer';

function InstantBooking({
  orgId,
  eventId,
  registrationIds,
  filepickerConfigs,
  checkin,
  checkout,
  locationAddress,
  numberOfRooms,
  presetSearch,
  presetDates,
  presetLocation,
  bookingConditions,
  roomBookingMandatory,
}) {
  return (
    <React.Suspense fallback={i18n.t('actions.loading')}>
      <EventHeaderSectionContainer eventId={eventId}>
        {
          ({
            headerData: {
              heroSection,
              event,
              headerMenu,
              isAuthenticated,
              registration,
            },
            removeAttendeeSession,
          }) => (
            <>
              {headerMenu
                && (
                  <EventHeader
                    orgId={orgId}
                    eventId={eventId}
                    logoUrl={headerMenu.brandingCompanyLogo}
                    alreadyRegistered={headerMenu.alreadyRegistered}
                    registration={registration}
                    removeAttendeeSession={removeAttendeeSession}
                    roomBookingMandatory={roomBookingMandatory}
                  />
                )}

              {heroSection && (
                <HeroSectionContainer
                  {...heroSection}
                  orgId={orgId}
                  eventId={eventId}
                  filepickerConfigs={filepickerConfigs}
                >
                  {
                    ({ attrs, settingsData }) => (
                      <HeroSection {...attrs}>
                        {isAuthenticated && (
                          <SettingsPanel {...settingsData} filepickerConfigs={filepickerConfigs} />
                        )}
                      </HeroSection>
                    )
                  }
                </HeroSectionContainer>
              )}

              <InstantBookingContainer
                orgId={orgId}
                eventId={eventId}
                registrationIds={registrationIds}
                checkin={checkin}
                checkout={checkout}
                locationAddress={locationAddress}
                numberOfRooms={numberOfRooms}
                isEventBookingPages
                presetSearch={presetSearch}
                presetDates={presetDates}
                presetLocation={presetLocation}
                bookingConditions={bookingConditions}
              >
                {
                  ({
                    data: {
                      propertySearch,
                      properties,
                      hasMoreProperties,
                      hasAvailability,
                      lastPage,
                      filters,
                      hasUnappliedFilters,
                      hashedMeetingRoomInfoByProp,
                      hashedPropertyImagesByProp,
                      suggestionsByName,
                      filterByName,
                    },
                    handleLocationChange,
                    handleCheckinChange,
                    handleCheckoutChange,
                    handleLoadMoreProperties,
                    handleStarChange,
                    handleChainChange,
                    handlePriceChange,
                    handleGuestRoomsChange,
                    handleMeetingRoomsChange,
                    handleApplyFilters,
                    handleChangeSearch,
                    handleBookNow,
                    handleGetMeetingRoomInfo,
                    handleGetPropertyImages,
                    handleFilterByName,
                    resetFilterByName,
                    handleFetchSuggestions,
                    handleSuggestionSelected,
                    handleSuggestionsClear,
                    setActiveProperty,
                    activePropertyId,
                    isMapView,
                    searchPosition,
                    toggleMapView,
                    loading,
                    handleSorting,
                    error,
                  }) => (
                    <>
                      {!loading && error && (
                        <div className="ui negative message">
                          <div className="header">
                            {i18n.t('errors.notices.search_failed')}
                          </div>
                          <p>
                            {error}
                          </p>
                        </div>
                      )}
                      {bookingConditions && (
                        <div
                          className="tw-mx-7"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: bookingConditions }}
                        />
                      )}
                      {!presetSearch && (
                        <div className="ui message" id="enter-search-header">
                          <div className="header">
                            {i18n.t('instant_booking.notices.please_enter_location')}
                          </div>
                        </div>
                      )}
                      {event && (
                        <SearchForm
                          startDate={presetDates ? (checkin || event.startDate) : null}
                          endDate={presetDates ? (checkout || event.endDate) : null}
                          location_address={presetLocation ? (locationAddress || (propertySearch ? propertySearch.searchLocationAddress : event.location)) : null}
                          maxRoomsNumber={event.maxRoomsNumber}
                          guestRooms={numberOfRooms || event.guestRooms}
                          handleCheckinChange={handleCheckinChange}
                          handleCheckoutChange={handleCheckoutChange}
                          handleLocationChange={handleLocationChange}
                          handleChangeSearch={handleChangeSearch}
                          handleGuestRoomsChange={handleGuestRoomsChange}
                        />
                      )}
                      <div className="bid_search">
                        {loading && <Loader />}
                        {properties && !error && (
                          <>
                            <AdditionalFilters
                              onStarChange={handleStarChange}
                              onChainChange={handleChainChange}
                              onPriceChange={handlePriceChange}
                              onGuestRoomsChange={handleGuestRoomsChange}
                              onMeetingRoomsChange={handleMeetingRoomsChange}
                              onApplyFiltersClick={handleApplyFilters}
                              filters={filters}
                              hasUnapplied={hasUnappliedFilters}
                              hasAvailability={hasAvailability}
                            />
                            <SearchPropertiesResult
                              suggestionsByName={suggestionsByName}
                              filterByName={filterByName}
                              canEbid={false}
                              showRates={propertySearch ? propertySearch.showRates : true}
                              canBookNow
                              lastPage={lastPage}
                              properties={properties}
                              locationAddress={(locationAddress || (propertySearch ? propertySearch.searchLocationAddress : event.location))}
                              hasMoreProperties={hasMoreProperties}
                              hasPropertiesAvailability={hasAvailability}
                              handleLoadMoreProperties={handleLoadMoreProperties}
                              handleBookNow={handleBookNow}
                              handleGetMeetingRoomInfo={handleGetMeetingRoomInfo}
                              handleGetPropertyImages={handleGetPropertyImages}
                              isMapView={isMapView}
                              searchPosition={searchPosition}
                              toggleMapView={toggleMapView}
                              setActiveProperty={setActiveProperty}
                              activePropertyId={activePropertyId}
                              hashedMeetingRoomInfoByProp={hashedMeetingRoomInfoByProp}
                              hashedPropertyImagesByProp={hashedPropertyImagesByProp}
                              handleFilterByName={handleFilterByName}
                              resetFilterByName={resetFilterByName}
                              handleFetchSuggestions={handleFetchSuggestions}
                              handleSuggestionSelected={handleSuggestionSelected}
                              handleSuggestionsClear={handleSuggestionsClear}
                              onSortByChanged={handleSorting}
                            />
                          </>
                        )}
                      </div>
                    </>
                  )
                }
              </InstantBookingContainer>
            </>
          )
        }
      </EventHeaderSectionContainer>
    </React.Suspense>
  );
}

InstantBooking.defaultProps = {
  isEventBookingPages: true,
  registrationIds: [],
  checkin: null,
  checkout: null,
  locationAddress: null,
  numberOfRooms: null,
  presetSearch: true,
  presetDates: true,
  presetLocation: true,
  bookingConditions: null,
  roomBookingMandatory: true,
};

InstantBooking.propTypes = {
  filepickerConfigs: PropTypes.shape().isRequired,
  orgId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  isEventBookingPages: PropTypes.bool,
  registrationIds: PropTypes.arrayOf(PropTypes.string),
  checkin: PropTypes.string,
  checkout: PropTypes.string,
  locationAddress: PropTypes.string,
  numberOfRooms: PropTypes.string,
  presetSearch: PropTypes.bool,
  presetDates: PropTypes.bool,
  presetLocation: PropTypes.bool,
  bookingConditions: PropTypes.string,
  roomBookingMandatory: PropTypes.bool,
};

export default InstantBooking;
