import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';
import { i18n } from '../../../../../src/custom/Internationalization';

import AccordionItem from './AccordionItem';

const chainList = {
  'RT,FA,RT,BY,PU,RX,SB': 'AccorHotels',
  BW: 'Best Western',
  'ZCH,NZ,EZ,CC,CI,CZ,EO,MZ,QI,RI,SZ,UB': 'Choice Hotels',
  'EH,PY,CN,QQ,DT,ES,HX,GI,HH,HL,HT,HG,UP,RU,WA': 'Hilton Hotels and Resorts',
  'HY,HU': 'Hyatt',
  'HI,AN,VA,YO,CP,VN,UL,IN,IC,KC,SP,YZ': 'IHG',
  MS: 'Magnusson Hotels',
  'MC,SI,AR,AK,BG,CY,DE,EB,FN,GE,ET,MC,VC,OX,PR,BR,RC,XV,RZ,TO,AL,EL,GX,MD,LC,XR,TX,WH,WI': 'Marriott International Hotels',
  'NS,JY': 'nh Hotels',
  'RD,CX,PD,PK': 'Radisson Hotel Group',
  'RL,BV': 'RLHC',
  'ZHF,LQ,AA,BU,DI,DX,FE,BH,HJ,MT,RA,OZ,TQ,TL,WT,WG,WY': 'Wyndham Hotels and Resorts',
};

function Chain({
  active, chains, onChainChange,
}) {
  return (
    <AccordionItem title={i18n.t('instant_booking.filters.chain')} active={active}>
      <div>
        {
          Object.keys(chains).map((code, i) => (
            <div className="filter-chain-wrapper" key={i}>
              <Checkbox key={i} value={code} checked={chains[code]} label={chainList[code]} onClick={() => onChainChange(code)} />
            </div>
          ))
        }
      </div>
    </AccordionItem>
  );
}

Chain.propTypes = {
  onChainChange: PropTypes.func.isRequired,
  chains: PropTypes.object.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default Chain;
