import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import AccordionItem from './AccordionItem';
import 'rc-slider/assets/index.css';
import { i18n } from '../../../../../src/custom/Internationalization';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

function MeetingRooms({
  active,
  value,
  onMeetingRoomsChange,
}) {
  return (
    <AccordionItem title={i18n.t('instant_booking.filters.meeting_rooms')} active={active}>
      <div className="rc-slider-wrapper tw-p-4">
        <Range
          min={0}
          max={50}
          step={1}
          defaultValue={[0, 50]}
          marks={{ 0: 0, 50: '50+' }}
          value={[value.min, value.max]}
          onChange={(v) => { onMeetingRoomsChange({ min: v[0], max: v[1] }); }}
          tipFormatter={(v) => (v >= 50 ? '50+' : v)}
        />
      </div>
    </AccordionItem>
  );
}

MeetingRooms.defaultProps = {
  active: false,
};

MeetingRooms.propTypes = {
  active: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]).isRequired,
  onMeetingRoomsChange: PropTypes.func.isRequired,
};

export default MeetingRooms;
