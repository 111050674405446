/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import PropTypes from "prop-types";

import PriceBlock from "./PriceBlock";
import LogoBlock from "./LogoBlock";
import AddressBlock from "./AddressBlock";
import DetailsModal from "./DetailsModal";

class PropertyCard extends Component {
  render() {
    const {
      showRates,
      isMapView,
      property,
      activePropertyId,
      meetingRoomInfo,
      canEbid,
      isAdded,
      handleGetMeetingRoomInfo,
      handleAddBidRequest,
    } = this.props;

    const {
      id,
      propertyResultInfoId,
      name,
      address,
      distanceText,
      distanceDurationText,
      rating,
      totalGuestRooms,
      meetingRooms,
      displayRate,
      displayCurrency,
      isNegotiated,
      preferred,
      promoText,
      promoTextPreview,
      eventPromoText,
      thumb,
      fallbackImage,
    } = property;

    const { hasPropertiesAvailability, locationAddress } = this.props;
    const isActive = property.id === activePropertyId;
    const blockClass = isMapView
      ? "bid_property_card--mini"
      : "bid_property_card";
    const highlightClass = isActive ? " active" : "";

    return (
      <div className={blockClass + highlightClass} id={`property_${id}`}>
        <div className="bid_property_card__content">
          <div className="bid_property_card__details">
            <div className="bid_property_card__details_info">
              <DetailsModal
                meetingRoomInfo={meetingRoomInfo}
                property={property}
                canEbid={canEbid}
                handleAddBidRequest={handleAddBidRequest}
                isAdded={isAdded}
                handleGetMeetingRoomInfo={handleGetMeetingRoomInfo}
              >
                <LogoBlock image={thumb} fallbackImage={fallbackImage} />
              </DetailsModal>
              <AddressBlock
                name={name}
                address={address}
                distanceText={distanceText}
                distanceDurationText={distanceDurationText}
                rating={rating}
                totalGuestRooms={totalGuestRooms}
                meetingRooms={meetingRooms}
                locationAddress={locationAddress}
                isNegotiated={isNegotiated}
                preferred={preferred}
                promoText={promoText}
                promoTextPreview={promoTextPreview}
                eventPromoText={eventPromoText}
                meetingRoomInfo={meetingRoomInfo}
                property={property}
                canEbid={canEbid}
                isAdded={isAdded}
                handleAddBidRequest={handleAddBidRequest}
                handleGetMeetingRoomInfo={handleGetMeetingRoomInfo}
              />
            </div>

            <PriceBlock
              className={isMapView ? "" : "tw-flex min-[993px]:tw-hidden"}
              showRates={showRates}
              hasPropertiesAvailability={hasPropertiesAvailability}
              displayRate={displayRate}
              displayCurrency={displayCurrency}
              isNegotiated={isNegotiated || false}
              propertyResultInfoId={propertyResultInfoId}
              {...this.props}
            />
          </div>

          <PriceBlock
            className={
              isMapView ? "tw-hidden" : "tw-hidden min-[993px]:tw-flex"
            }
            showRates={showRates}
            hasPropertiesAvailability={hasPropertiesAvailability}
            displayRate={displayRate}
            displayCurrency={displayCurrency}
            isNegotiated={isNegotiated || false}
            propertyResultInfoId={propertyResultInfoId}
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

PropertyCard.defaultProps = {
  showRates: true,
  isMapView: false,
  isAdded: false,
  preferred: false,
  promoText: "",
  promoTextPreview: "",
  eventPromoText: "",
};

PropertyCard.propTypes = {
  showRates: PropTypes.bool,
  isMapView: PropTypes.bool,
  property: PropTypes.object.isRequired,
  activePropertyId: PropTypes.number,
  meetingRoomInfo: PropTypes.object,
  canEbid: PropTypes.bool.isRequired,
  isAdded: PropTypes.bool,
  handleAddBidRequest: PropTypes.func.isRequired,
  handleGetMeetingRoomInfo: PropTypes.func.isRequired,
  preferred: PropTypes.bool,
  promoText: PropTypes.string,
  promoTextPreview: PropTypes.string,
  eventPromoText: PropTypes.string,
  hasPropertiesAvailability: PropTypes.bool.isRequired,
  locationAddress: PropTypes.string.isRequired,
};

export default PropertyCard;
