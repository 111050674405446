import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Button from '@mui/material/Button';
import styled from 'styled-components';
import LocationField from '../../../Common/Form/LocationField/LocationField';
import TextField from '../../../Common/Form/TextField';
import DateField from '../../../Common/Form/DateField/DateField';
import NumberField from '../../../Common/Form/NumberField';
import colors from '../../../Common/constants/colors';
import { i18n, i18nARAttr } from '../../../../src/custom/Internationalization';

const FormArea = styled.div`
  background-color: ${colors.grey100};
  border-bottom: 1px solid ${colors.grey200};
  padding: 0.5em 1em;
`;

const FormAreaWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin: auto;

  @media (max-width: 992px) {
    flex-flow: column nowrap;
    flex: 1 0 auto;
  }

  .groupize-button {
    background-color: ${colors.groupizeBrand};
    box-shadow: none;
    color: ${colors.white};
    margin: 0.5em;

    &:hover {
      background-color: ${colors.groupizeBrand};
    }

    &:active {
      box-shadow: none;
    }
  }
`;

function SearchForm({
  eventName,
  startDate: initStartDate,
  endDate: initEndDate,
  guestRooms,
  targetAttendance,
  location,
  handleLocationChange,
  handleCheckinChange,
  handleCheckoutChange,
  handleChangeSearch,
  allowDateChange,
}) {
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);

  useEffect(() => {
    setEndDate(initEndDate);
  }, [initEndDate]);

  useEffect(() => {
    setStartDate(initStartDate);
  }, [initStartDate]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    handleCheckinChange(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    handleCheckoutChange(date);
  };

  useEffect(() => {
    const currentDay = moment(startDate);
    if (currentDay.isAfter(endDate) || !moment(endDate).isValid()) {
      currentDay.add(1, 'd');
      handleEndDateChange(currentDay.format('YYYY-MM-DD'));
    }
  }, [startDate]);

  return (
    <FormArea>
      <FormAreaWrapper>
        <LocationField
          id="locationAddress"
          label={i18nARAttr('property_search.location_address')}
          address={location}
          onLocationSelected={(address, LatLng) => handleLocationChange(address)}
        />
        <TextField
          id="eventName"
          label={i18nARAttr('event.name')}
          value={eventName}
          onChange={() => { }}
          minheight="20px"
        />
        <DateField
          id="eventStartDate"
          label={i18nARAttr('property_search.checkin')}
          value={startDate}
          onDateSelected={handleStartDateChange}
          isDisabled={!allowDateChange}
          minDate={moment().format()}
          minheight="20px"
          className="ebid-date-field"
        />
        <DateField
          id="eventEndDate"
          label={i18nARAttr('property_search.checkout')}
          value={endDate}
          onDateSelected={handleEndDateChange}
          isDisabled={!allowDateChange}
          minDate={startDate || moment().format()}
          minheight="20px"
          className="ebid-date-field"
        />
        <NumberField
          id="number"
          label={i18nARAttr('property_search.number_of_rooms')}
          defaultNum={guestRooms}
          onChanged={() => { }}
          minheight="20px"
        />
        <NumberField
          id="number"
          label={i18nARAttr('event.estimated_target_attendance')}
          defaultNum={targetAttendance}
          onChanged={() => { }}
          minheight="20px"
        />
        <Button
          size="large"
          color="primary"
          variant="contained"
          className="groupize-button"
          onClick={handleChangeSearch}
        >
          {i18n.t('actions.search')}
        </Button>
      </FormAreaWrapper>
    </FormArea>
  );
}
SearchForm.defaultProps = {
  location: '',
  guestRooms: 0,
};

SearchForm.propTypes = {
  eventName: PropTypes.string.isRequired,
  location: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  guestRooms: PropTypes.number,
  targetAttendance: PropTypes.number.isRequired,
  handleLocationChange: PropTypes.func.isRequired,
  handleChangeSearch: PropTypes.func.isRequired,
};

export default SearchForm;
