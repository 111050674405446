import update from 'immutability-helper';
import reduce from 'lodash/reduce';
import { isEqual } from 'lodash/fp';
import some from 'lodash/some';

export const defaultPriceRangeFilter = { min: 0, max: 1000 };
export const defaultGuestRoomsRangeFilter = { min: 0, max: 1000 };
export const defaultMeetingRoomsRangeFilter = { min: 0, max: 50 };

export const initialFiltersState = {
  stars: {
    current: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
    },
    applied: {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
    },
  },
  // TODO: FOR FILTERS, double check the steps is 10, so the MAX must to be a 10 multipler.
  priceRange: {
    current: defaultPriceRangeFilter,
  },
  guestRoomsRange: {
    current: defaultGuestRoomsRangeFilter,
  },
  meetingRoomsRange: {
    current: defaultMeetingRoomsRangeFilter,
  },
  chains: {
    current: {
      'RT,FA,RT,BY,PU,RX,SB': false,
      BW: false,
      'ZCH,NZ,EZ,CC,CI,CZ,EO,MZ,QI,RI,SZ,UB': false,
      'EH,PY,CN,QQ,DT,ES,HX,GI,HH,HL,HT,HG,UP,RU,WA': false,
      'HY,HU': false,
      'HI,AN,VA,YO,CP,VN,UL,IN,IC,KC,SP,YZ': false,
      MS: false,
      'MC,SI,AR,AK,BG,CY,DE,EB,FN,GE,ET,MC,VC,OX,PR,BR,RC,XV,RZ,TO,AL,EL,GX,MD,LC,XR,TX,WH,WI': false,
      'NS,JY': false,
      'RD,CX,PD,PK': false,
      'RL,BV': false,
      'ZHF,LQ,AA,BU,DI,DX,FE,BH,HJ,MT,RA,OZ,TQ,TL,WT,WG,WY': false,
    },
    applied: {
      'RT,FA,RT,BY,PU,RX,SB': false,
      BW: false,
      'ZCH,NZ,EZ,CC,CI,CZ,EO,MZ,QI,RI,SZ,UB': false,
      'EH,PY,CN,QQ,DT,ES,HX,GI,HH,HL,HT,HG,UP,RU,WA': false,
      'HY,HU': false,
      'HI,AN,VA,YO,CP,VN,UL,IN,IC,KC,SP,YZ': false,
      MS: false,
      'MC,SI,AR,AK,BG,CY,DE,EB,FN,GE,ET,MC,VC,OX,PR,BR,RC,XV,RZ,TO,AL,EL,GX,MD,LC,XR,TX,WH,WI': false,
      'NS,JY': false,
      'RD,CX,PD,PK': false,
      'RL,BV': false,
      'ZHF,LQ,AA,BU,DI,DX,FE,BH,HJ,MT,RA,OZ,TQ,TL,WT,WG,WY': false,
    },
  },
};

export function hasUnappliedFilters() {
  return some(this.state.filters, ({ applied, current }) => !isEqual(current, applied));
}

export function handleStarChange(star) {
  const newData = {};
  newData[star] = { $set: !this.state.filters.stars.current[star] };
  const filters = update(this.state.filters, {
    stars: { current: { ...newData } },
  });

  this.setState({ filters });
}

export function handleChainChange(chain) {
  const newData = {};
  newData[chain] = { $set: !this.state.filters.chains.current[chain] };
  const filters = update(this.state.filters, {
    chains: { current: { ...newData } },
  });

  this.setState({ filters });
}

export function handlePriceChange(range) {
  const value = range.max > 1000 ? { ...range, max: 1000 } : range;

  const filters = update(this.state.filters, {
    $merge: {
      priceRange: { ...this.state.filters.priceRange, current: value },
    },
  });

  this.setState({ filters });
}

export function handleGuestRoomsChange(range) {
  const value = range.max > 1000 ? { ...range, max: 1000 } : range;

  const filters = update(this.state.filters, {
    $merge: {
      guestRoomsRange: { ...this.state.filters.guestRoomsRange, current: value },
    },
  });

  this.setState({ filters });
}

export function handleMeetingRoomsChange(range) {
  const value = range.max > 50 ? { ...range, max: 50 } : range;

  const filters = update(this.state.filters, {
    $merge: {
      meetingRoomsRange: { ...this.state.filters.meetingRoomsRange, current: value },
    },
  });

  this.setState({ filters });
}

export function handleApplyFilters() {
  const extractStarsFiltersToApply = ({ newFilterState: { current } }) => {
    const updatedStars = reduce(current, (result, value, star) => {
      if (value === true) {
        result.push(star);
      }

      return result;
    }, []).join(',');

    return { newAppliedFilterState: updatedStars, appliedFilterName: 'stars' };
  };

  const extractChainsFiltersToApply = ({ newFilterState: { current } }) => {
    const updatedChains = reduce(current, (result, value, code) => {
      if (value === true) {
        result.push(code);
      }

      return result;
    }, []).join(',');

    return { newAppliedFilterState: updatedChains, appliedFilterName: 'chains' };
  };

  const extractPriceRangeFiltersToApply = ({ newFilterState: { current } }) => {
    const newAppliedFilterState = `${current.min === 0 ? '' : current.min},${current.max === 1000 ? '' : current.max}`;
    return { newAppliedFilterState, appliedFilterName: 'price' };
  };

  const extractGuestRoomsRangeFiltersToApply = ({ newFilterState: { current } }) => {
    const newAppliedFilterState = `${current.min === 0 ? '' : current.min},${current.max === 1000 ? '' : current.max}`;
    return { newAppliedFilterState, appliedFilterName: 'guestRooms' };
  };

  const extractMeetingRoomsRangeFiltersToApply = ({ newFilterState: { current } }) => {
    const newAppliedFilterState = `${current.min === 0 ? '' : current.min},${current.max === 50 ? '' : current.max}`;
    return { newAppliedFilterState, appliedFilterName: 'meetingRooms' };
  };

  if (this.hasUnappliedFilters() === false) {
    return;
  }

  const reducers = [
    {
      filterName: 'stars',
      fn: extractStarsFiltersToApply,
    },
    {
      filterName: 'chains',
      fn: extractChainsFiltersToApply,
    },
    {
      filterName: 'priceRange',
      fn: extractPriceRangeFiltersToApply,
    },
    {
      filterName: 'guestRoomsRange',
      fn: extractGuestRoomsRangeFiltersToApply,
    },
    {
      filterName: 'meetingRoomsRange',
      fn: extractMeetingRoomsRangeFiltersToApply,
    },
  ];

  const newState = reduce(reducers, ({ filters, appliedFilters }, { filterName, fn }) => {
    const { current, applied } = filters[filterName];

    if (isEqual(current, applied) === false) {
      let newFilterData = {};
      const newFilterState = { current: { ...current }, applied: { ...current } };
      newFilterData[filterName] = newFilterState;
      const updatedFilters = update(filters, {
        $merge: { ...newFilterData },
      });

      newFilterData = {};
      const { appliedFilterName, newAppliedFilterState } = fn({ newFilterState, appliedFilters });
      newFilterData[appliedFilterName] = newAppliedFilterState;
      const updatedAppliedFilters = update(appliedFilters, {
        $merge: { ...newFilterData },
      });

      return { filters: updatedFilters, appliedFilters: updatedAppliedFilters };
    }
    return { filters, appliedFilters };
  }, { filters: this.state.filters, appliedFilters: this.state.appliedFilters });

  this.requestMoreProperties(true, newState.appliedFilters, 1);

  this.setState({ ...newState, lastPage: 1 });
}
