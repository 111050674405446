import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropertyCard from '../../PropertyCard';

function PropertyList({
  showRates,
  properties,
  hasMoreProperties,
  handleLoadMoreProperties,
  handleGetMeetingRoomInfo,
  hashedMeetingRoomInfoByProp,
  hasPropertiesAvailability,
  locationAddress,
  handleBookNow,
  handleAddBidRequest,
  handleRemoveBid,
  bids,
  isMapView,
  setActiveProperty,
  activePropertyId,
  canBookNow,
  canEbid,
  isEventBookingPages,
}) {
  const wrapperClass = isMapView ? 'bid_properties__list_wrapper hide' : 'tw-flex-grow';
  const blockClass = isMapView ? 'bid_properties__list tw-flex-grow-0' : 'bid_properties__list tw-group/list-view';

  const Loader = (
    <div className="loader">
      <div key={-1} className="ui active centered inline loader" />
    </div>
  );

  return (
    <div className={wrapperClass} id="properties_scroll">
      <div className="tw-px-8 tw-py-4" id="properties">
        <InfiniteScroll
          dataLength={properties.length}
          next={handleLoadMoreProperties}
          hasMore={hasMoreProperties}
          loader={Loader}
          className={blockClass}
          scrollableTarget="bid-request-container"
        >
          {
              properties.map((property) => {
                const isAdded = (bids.findIndex(
                  ({ property: { id } }) => id === property.id,
                ) !== -1);
                return (
                  <PropertyCard
                    key={property.id}
                    id={property.id}
                    showRates={showRates}
                    property={property}
                    isAdded={isAdded || false}
                    isMapView={isMapView}
                    setActiveProperty={setActiveProperty}
                    activePropertyId={activePropertyId}
                    locationAddress={locationAddress}
                    hasPropertiesAvailability={hasPropertiesAvailability}
                    handleGetMeetingRoomInfo={handleGetMeetingRoomInfo}
                    handleBookNow={handleBookNow}
                    handleAddBidRequest={handleAddBidRequest}
                    handleRemoveBid={handleRemoveBid}
                    canBookNow={canBookNow}
                    canEbid={canEbid}
                    isEventBookingPages={isEventBookingPages}
                    meetingRoomInfo={hashedMeetingRoomInfoByProp[property.id]}
                  />
                );
              })
            }
        </InfiniteScroll>
      </div>
    </div>
  );
}

PropertyList.defaultProps = {
  showRates: true,
  isEventBookingPages: false,
  bids: [],
  isMapView: false,
  activePropertyId: null,
};

PropertyList.propTypes = {
  showRates: PropTypes.bool,
  properties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  hasMoreProperties: PropTypes.bool.isRequired,
  handleLoadMoreProperties: PropTypes.func.isRequired,
  handleGetMeetingRoomInfo: PropTypes.func.isRequired,
  hashedMeetingRoomInfoByProp: PropTypes.shape().isRequired,
  hasPropertiesAvailability: PropTypes.bool.isRequired,
  locationAddress: PropTypes.string.isRequired,
  handleBookNow: PropTypes.func.isRequired,
  handleAddBidRequest: PropTypes.func.isRequired,
  handleRemoveBid: PropTypes.func.isRequired,
  bids: PropTypes.arrayOf(PropTypes.shape()),
  isMapView: PropTypes.bool,
  setActiveProperty: PropTypes.func.isRequired,
  activePropertyId: PropTypes.number,
  canBookNow: PropTypes.bool.isRequired,
  canEbid: PropTypes.bool.isRequired,
  isEventBookingPages: PropTypes.bool,
};

export default PropertyList;
