import StarRating from './StarRating';
import PricePerNight from './PricePerNight';
import GuestRooms from './GuestRooms';
import MeetingRooms from './MeetingRooms';
import Chain from './Chain';

export default {
  StarRating,
  PricePerNight,
  GuestRooms,
  MeetingRooms,
  Chain,
};
