import React from 'react';
import PropTypes from 'prop-types';
import times from 'lodash/times';
import { Modal } from 'semantic-ui-react';
import CustomTriggerTextModal from '../../CustomTriggerTextModal';
import DetailsModal from '../DetailsModal';
import { i18n } from '../../../../../src/custom/Internationalization';

function AddressBlock({
  name,
  address,
  distanceText,
  distanceDurationText,
  rating,
  totalGuestRooms,
  meetingRooms,
  locationAddress,
  preferred,
  promoText,
  promoTextPreview,
  eventPromoText,
  meetingRoomInfo,
  property,
  canEbid,
  handleAddBidRequest,
  handleGetMeetingRoomInfo,
  isAdded,
}) {
  return (
    <div className="booking_property_card__address-block">
      <div className="tw-flex">
        {preferred && (
          <div className="booking_property_card__negotiated tw-mb-2.5 preferred-flag">
            <i className="check circle icon tw-mr-2.5" />
            {i18n.t('instant_booking.notices.preferred')}
          </div>
        )}
        <i className="icon close tw-ml-auto hover:tw-cursor-pointer hover:tw-text-info-500 group-odd/list-view:tw-hidden group-even/list-view:tw-hidden" />
      </div>

      <DetailsModal
        meetingRoomInfo={meetingRoomInfo}
        property={property}
        canEbid={canEbid}
        handleAddBidRequest={handleAddBidRequest}
        isAdded={isAdded}
        handleGetMeetingRoomInfo={handleGetMeetingRoomInfo}
        triggerLinkClass="booking_property_card__title group-hover/map-view:tw-text-[#424242] group-hover/map-view:tw-cursor-grab"
      >
        {name}
      </DetailsModal>

      {(rating || rating === 0)
        && (
          <p className="booking_property_card__rating">
            {
              times(5, (i) => (<i key={i} className={i + 1 > rating ? 'star icon outline' : 'star icon'} />))
            }
          </p>
        )}

      <p className="booking_property_card__address tw-mb-0">
        {address}
      </p>

      <p className="booking_property_card__distance">
        <span className="tw-mr-4 time-and-distance">
          <i className="icon car" />
          <span className="distance distance-present">
            {distanceText}
          </span>
        </span>
        {distanceDurationText?.length > 0 && (
          <span className="tw-mr-4 time-and-distance">
            <i className="icon wait" />
            <span className="duration">{distanceDurationText}</span>
            <span className="origin">
              {i18n.t('instant_booking.messages.distant_origin', { location: locationAddress })}
            </span>
          </span>
        )}
      </p>

      <p className="booking_property_card__address">
        {totalGuestRooms > -1
          && (
            <span className="tw-mr-4">
              <span className="sleeping_rooms icon" />
              <span className="tw-font-semibold">{totalGuestRooms}</span>
              &nbsp;
              {i18n.t('hotel_card.headings.guest_rooms')}
            </span>
          )}
        {meetingRooms > -1
          && (
            <span className="tw-mr-4">
              <span className="max_seating_rooms icon" />
              <span className="tw-font-semibold">{meetingRooms}</span>
              &nbsp;
              {i18n.t('hotel_card.headings.meeting_rooms')}
            </span>
          )}
      </p>

      {eventPromoText
        && (
          <div className="promo_text_button_wrapper">
            <div className="promo_text_icon"><i className="star icon" /></div>
            <div className="promo_text">{eventPromoText}</div>
          </div>
        )}
      {promoTextPreview && !eventPromoText
        && (
          <CustomTriggerTextModal
            customTrigger={(
              <div className="promo_text_button_wrapper">
                <div className="promo_text_icon"><i className="star icon" /></div>
                <div className="promo_text">
                  {promoTextPreview}
                  &nbsp;
                  <span className="read_more_text">{i18n.t('instant_booking.actions.read_more')}</span>
                </div>
              </div>
            )}
            headerText={(
              <div className="promo_text_header_wrapper">
                <div className="promo_text_icon"><i className="star icon" /></div>
                <div className="promo_text">{i18n.t('instant_booking.headings.specials')}</div>
              </div>
            )}
            mainText={<div dangerouslySetInnerHTML={{ __html: promoText }} />}
            modalHeader={(
              <div className="promo_header_wrapper">
                <Modal.Header>
                  <div className="promo_text_header_wrapper">
                    <div className="promo_text_icon"><i className="star icon" /></div>
                    <div className="promo_text">{i18n.t('instant_booking.headings.specials')}</div>
                  </div>
                </Modal.Header>
              </div>
            )}
          />
        )}
    </div>
  );
}

AddressBlock.propTypes = {
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  distanceText: PropTypes.string.isRequired,
  distanceDurationText: PropTypes.string.isRequired,
  rating: PropTypes.number,
  totalGuestRooms: PropTypes.number.isRequired,
  meetingRooms: PropTypes.number.isRequired,
  preferred: PropTypes.bool,
  promoText: PropTypes.string,
  promoTextPreview: PropTypes.string,
  eventPromoText: PropTypes.string,
};

export default AddressBlock;
