/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Divider } from 'semantic-ui-react';
import { isValuePresent } from '../../../../modules/isValuePresent';
import { i18n } from '../../../../../../src/custom/Internationalization';

class MeetingSpace extends React.Component {
  render() {
    const { meetingRoomInfo } = this.props;

    return (
      <div id="meeting_space" name="meeting_space" className="meeting_services__section">
        <p className="meeting_services__heading">
          {i18n.t('hotel_card.headings.meeting_space')}
        </p>
        <div className="content">
          <p className="meeting_services__heading_title">{i18n.t('hotel_card.headings.room_dimensions')}</p>
          <div className="property_rooms">
            {isValuePresent(meetingRoomInfo.meetingRoomCount) && (
              <div className="property_rooms__item">
                <p className="property_rooms__icon">
                  <span className="meeting_rooms icon" />
                </p>
                <p className="property_rooms__title">{i18n.t('hotel_card.labels.num_meeting_rooms')}</p>
                <p className="property_rooms__value">
                  {meetingRoomInfo.meetingRoomCount}
                </p>
              </div>
            )}
            <div className="property_rooms__item">
              <p className="property_rooms__icon">
                <span className="capacity_largest_room icon" />
              </p>
              <p className="property_rooms__title">{i18n.t('hotel_card.labels.largest_room_size')}</p>
              <p className="property_rooms__value">
                <span>{meetingRoomInfo.largestRoomSize}</span>
              </p>
            </div>
            {isValuePresent(meetingRoomInfo.maxSeatingCapacity) && (
              <div className="property_rooms__item">
                <p className="property_rooms__icon">
                  <span className="max_seating_rooms icon" />
                </p>
                <p className="property_rooms__title">{i18n.t('hotel_card.labels.max_seating_capacity')}</p>
                <p className="property_rooms__value">
                  <span>{meetingRoomInfo.maxSeatingCapacity}</span>
                </p>
              </div>
            )}
            {isValuePresent(meetingRoomInfo.breakoutRoomsCount) && (
              <div className="property_rooms__item">
                <p className="property_rooms__icon">
                  <span className="breakout_rooms icon" />
                </p>
                <p className="property_rooms__title">{i18n.t('hotel_card.labels.num_breakout_rooms')}</p>
                <p className="property_rooms__value">{meetingRoomInfo.breakoutRoomsCount}</p>
              </div>
            )}
            {isValuePresent(meetingRoomInfo.totalMeetingArea) && (
              <div className="property_rooms__item">
                <p className="property_rooms__icon">
                  <span className="total_meeting_area icon" />
                </p>
                <p className="property_rooms__title">{i18n.t('hotel_card.labels.total_meeting_area')}</p>
                <p className="property_rooms__value">
                  <span>{meetingRoomInfo.totalMeetingArea}</span>
                </p>
              </div>
            )}
          </div>

          <Divider />

          <p className="meeting_services__heading_title">
            {i18n.t('hotel_card.headings.capacity_chart')}
          </p>
          <div className="capacity_chart">
            <div className="capacity_chart__line">
              <div className="capacity_chart__item" />
              <div className="capacity_chart__item top" />
              <div className="capacity_chart__item top" />
              <div className="capacity_chart__item top" />
              {
                meetingRoomInfo.roomSetups.map((roomSetup, i) => (
                  <div key={i} className="capacity_chart__item top">
                    <div className="room_type_img">
                      <img src={`/images/portal/style-diagram-${roomSetup}.jpg`} alt="meeting room" />
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="capacity_chart__line">
              <div className="capacity_chart__item title" />
              <div className="capacity_chart__item title">{i18n.t('hotel_card.labels.space')}</div>
              <div className="capacity_chart__item title">{i18n.t('hotel_card.labels.ceiling_height')}</div>
              <div className="capacity_chart__item title">{i18n.t('hotel_card.labels.size')}</div>
              {
                Object.keys(meetingRoomInfo.formats).map((formatName, i) => (
                  <div key={i} className="capacity_chart__item title">
                    <p className="capacity_chart__item-text">
                      {i18n.t(`ebids.setup.${formatName.toLowerCase()}`)}
                    </p>
                  </div>
                ))
              }
            </div>

            {
              meetingRoomInfo.roomDetails.map(({
                roomName, meetingRoomArea, roomDimension, roomSize, capacities,
              }, i) => (
                <div key={i} className="capacity_chart__line">
                  <div className="capacity_chart__item name">
                    {roomName}
                  </div>
                  <div className="capacity_chart__item">
                    <p className="capacity_chart__item-text">
                      {meetingRoomArea}
                    </p>
                  </div>
                  <div className="capacity_chart__item">
                    <p className="capacity_chart__item-text">
                      {roomDimension}
                    </p>
                  </div>
                  <div className="capacity_chart__item">
                    <p className="capacity_chart__item-text">{roomSize}</p>
                  </div>
                  {
                    Object.keys(meetingRoomInfo.formats).map((format, i) => (
                      <div key={i} className="capacity_chart__item">
                        <p className="capacity_chart__item-text">{capacities[format]}</p>
                      </div>
                    ))
                  }
                </div>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

MeetingSpace.propTypes = {};

export default MeetingSpace;
