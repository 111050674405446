import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import Button from '@mui/material/Button';
import { i18n } from '../../../../../src/custom/Internationalization';

const getSuggestionValue = ({ name }) => name;

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => {
  let txt = suggestion.name;
  if (suggestion == i18n.t('instant_booking.venue_options.all_venues')) {
    txt = i18n.t('instant_booking.venue_options.all_venues');
  }

  return (
    <div>
      {txt}
    </div>
  );
};

function Filters({
  suggestionsByName,
  handleFetchSuggestions,
  handleSuggestionSelected,
  handleFilterByName,
  resetFilterByName,
  filterByName,
}) {
  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: i18n.t('instant_booking.placeholders.search_by_venue_name'),
    name: 'name_search',
    value: filterByName,
    onChange: (event, { newValue, method }) => handleFilterByName(newValue, method),
  };

  return (
    <>
      <Autosuggest
        suggestions={suggestionsByName}
        onSuggestionsFetchRequested={handleFetchSuggestions}
        onSuggestionsClearRequested={resetFilterByName}
        onSuggestionSelected={handleSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
      {filterByName
        && filterByName !== ''
        && (
          <Button
            size="small"
            onClick={() => handleSuggestionSelected('x-button-clicked', {
              suggestion: i18n.t('instant_booking.venue_options.all_venues'),
            })}
          >
            Clear
          </Button>
        )}
    </>
  );
}

Filters.propTypes = {
  suggestionsByName: PropTypes.array.isRequired,
  filterByName: PropTypes.string.isRequired,
  handleFilterByName: PropTypes.func.isRequired,
  resetFilterByName: PropTypes.func.isRequired,
  handleFetchSuggestions: PropTypes.func.isRequired,
};

export default Filters;
