import React from 'react';
import PropTypes from 'prop-types';
import GMapArea from '../../../../Common/GoogleMap/GMapArea';
import MarkerPropertyInfo from '../../MarkerPropertyInfo';

function PropertyMap({
  properties,
  showRates,
  bidsToBeCreated,
  setActiveProperty,
  activePropertyId,
  searchPosition,
  hasPropertiesAvailability,
  handleAddBidRequest,
  handleBookNow,
  handleRemoveBid,
  canBookNow,
  canEbid,
}) {
  const markers = [];

  properties.map((property) => {
    const isAdded = (bidsToBeCreated && bidsToBeCreated.findIndex(({ property: { id } }) => id === property.id) !== -1);
    const icon = isAdded ? 'https://s3.amazonaws.com/groupizereg-production/icons/place_green_m.png'
      : 'https://s3.amazonaws.com/groupizereg-production/icons/place_grey_m.png';
    const isActive = property.id === activePropertyId;

    return markers.push({
      id: property.id,
      position: {
        lat: property.latitude,
        lng: property.longitude,
      },
      icon,
      isActive,
      content: <MarkerPropertyInfo
        property={property}
        showRates={showRates}
        hasPropertiesAvailability={hasPropertiesAvailability}
        handleAddBidRequest={handleAddBidRequest}
        handleBookNow={handleBookNow}
        handleRemoveBid={handleRemoveBid}
        canBookNow={canBookNow}
        canEbid={canEbid}
        isAdded={isAdded}
      />,
      callback: () => { setActiveProperty(property.id); },
    });
  });

  return (
    <div className="bid_properties__map tw-group/map-view">
      <GMapArea
        poi={{
          icon: 'https://s3.amazonaws.com/groupizereg-production/icons/poi_map_icon_m.png',
          position: searchPosition,
        }}
        markers={markers}
      />
    </div>
  );
}

PropertyMap.defaultProps = {
  showRates: true,
};

PropertyMap.propTypes = {
  properties: PropTypes.arrayOf(PropTypes.shape).isRequired,
  showRates: PropTypes.bool,
};

export default PropertyMap;
