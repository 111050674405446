/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import GMapArea from '../../../../../Common/GoogleMap/GMapArea';
import MarkerPropertyInfo from '../../../MarkerPropertyInfo';
import { i18n } from '../../../../../../src/custom/Internationalization';

class Location extends Component {
  render() {
    const { property } = this.props;
    const marker = {
      id: property.id,
      position: {
        lat: property.latitude,
        lng: property.longitude,
      },
      icon: 'https://s3.amazonaws.com/groupizereg-production/icons/poi_map_icon_m.png',
      isActive: true,
      content: <MarkerPropertyInfo property={property} />,
    };

    return (
      <div id="location" name="location" className="meeting_services__section">
        <p className="meeting_services__heading">
          {i18n.t('hotel_card.headings.location')}
        </p>
        <div className="content">
          <GMapArea poi={marker} />
        </div>
      </div>
    );
  }
}

Location.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  property: PropTypes.object.isRequired,
};

export default Location;
