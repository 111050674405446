import React, { Component } from 'react';
import { Modal, Sticky } from 'semantic-ui-react';
import {
  Link as Anchor, Events, scrollSpy,
} from 'react-scroll';
import styled from 'styled-components';
import isUndefined from 'lodash/isUndefined';
import Loader from '../../Loader';
import Description from './sections/Description';
import MeetingSpace from './sections/MeetingSpace';
import Amenities from './sections/Amenities';
import Gallery from './sections/Gallery';
import GuestRooms from './sections/GuestRooms';
import Location from './sections/Location';
import { isValuePresent } from '../../../modules/isValuePresent';
import { i18n } from '../../../../../src/custom/Internationalization';

const ImgThumb = styled.div`
  position: absolute;
  top: 0;
  right:0;
  bottom: 0;
  left: 0;
  background-color: transparent;

  ${(props) => props.image && `
    background-image: url("${props.image}"), url("${props.fallbackImage}");
    background-size: cover;
    background-position: center;
  `}
`;

class DetailsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleContextRef = this.handleContextRef.bind(this);
  }

  componentDidMount() {
    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }

  handleContextRef(contextRef) {
    if (contextRef) {
      this.setState({ contextRef });
    }
  }

  handleClose() {
    this.setState({ modalOpen: false });
  }

  handleOpen() {
    const { handleGetMeetingRoomInfo, property, meetingRoomInfo } = this.props;

    if ((isUndefined(meetingRoomInfo) || !meetingRoomInfo) && isValuePresent(property)) {
      handleGetMeetingRoomInfo(property.id, property.propertyContentId);
      this.setState({ modalOpen: true });
    }
  }

  render() {
    const {
      meetingRoomInfo, property, canEbid, handleAddBidRequest, isAdded, triggerLinkClass, children,
    } = this.props;
    const { contextRef } = this.state;
    const anchor_options = {
      activeClass: 'active',
      containerId: 'modal_content',
      className: 'item',
      spy: true,
      smooth: true,
      duration: 300,
      delay: 50,
      offset: -65,
      isDynamic: true,
      ignoreCancelEvents: false,
    };

    return (
      <Modal
        className="fullscreen meeting_services"
        trigger={<a className={triggerLinkClass} onClick={this.handleOpen}>{children}</a>}
        closeOnDimmerClick
        closeOnEscape
        open={this.state.modalOpen}
        onClose={this.handleClose}
      >
        <Modal.Content id="modal_content" className="scrolling content tw-p-0">
          {(isUndefined(meetingRoomInfo) || !meetingRoomInfo) && (<Loader />)}

          {meetingRoomInfo && (
            <div ref={this.handleContextRef}>
              <div className="meeting_services__section">
                <a className="close" onClick={this.handleClose}>
                  <i className="icon big times circle" />
                </a>
                <div className="swiper-container meeting_services__slider">
                  <div>
                    <ImgThumb image={property.image} fallbackImage={property.fallbackImage} />
                  </div>
                </div>
                <div className="meeting_services__title-area">
                  <div className="tw-flex">
                    <div className="tw-m-0">
                      <p className="meeting_services__title">
                        {property.name}
                      </p>
                      <p className="meeting_services__title-text">
                        {property.address}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <Sticky context={contextRef} className="meeting_services__section">
                <div id="sticky_modal_menu" className="booking_property_card__details">
                  <div className="tw-flex">
                    <div className="ui pointing secondary huge menu tw-mb-0">
                      {isValuePresent(meetingRoomInfo.description) && (
                        <Anchor to="description" {...anchor_options}>
                          {i18n.t('hotel_card.headings.description')}
                        </Anchor>
                      )}
                      {isValuePresent(meetingRoomInfo.roomDetails) && (
                        <Anchor to="meeting_space" {...anchor_options}>
                          {i18n.t('hotel_card.headings.meeting_space')}
                        </Anchor>
                      )}
                      {(isValuePresent(meetingRoomInfo.amenitiesSet.property)
                        || isValuePresent(meetingRoomInfo.amenitiesSet.meetingService)
                        || isValuePresent(meetingRoomInfo.amenitiesSet.propertyBusinessService)) && (
                          <Anchor to="amenities" {...anchor_options}>
                            {i18n.t('hotel_card.headings.amenities_and_services')}
                          </Anchor>
                      )}
                      {isValuePresent(meetingRoomInfo.propertyImages) && (
                        <Anchor to="image_gallery" {...anchor_options}>
                          {i18n.t('hotel_card.headings.image_gallery')}
                        </Anchor>
                      )}
                      {isValuePresent(parseInt(meetingRoomInfo.totalGuestRooms)) && (
                        <Anchor to="guest_room" {...anchor_options}>
                          {i18n.t('hotel_card.headings.guest_rooms')}
                        </Anchor>
                      )}
                      <Anchor to="location" {...anchor_options}>
                        {i18n.t('hotel_card.headings.location')}
                      </Anchor>
                    </div>
                    {canEbid && !isAdded
                      && (
                        <div className="tw-ml-auto">
                          <a onClick={() => { handleAddBidRequest(property.id); }} className="ui groupize large button tw-uppercase tw-m-4 tw-mr-12 add_to_list">
                            <i className="envelope icon" />
                            <span className="tw-uppercase">{i18n.t('hotel_card.actions.add_to_list')}</span>
                          </a>
                        </div>
                      )}
                    {canEbid && isAdded
                      && (
                        <div className="tw-ml-auto">
                          <div className="ui groupize large button disabled tw-uppercase tw-m-4 tw-mr-12 add_to_list">
                            <i className="check icon" />
                            <span className="tw-uppercase">{i18n.t('hotel_card.labels.added_to_list')}</span>
                          </div>
                        </div>
                      )}

                  </div>
                </div>
              </Sticky>

              {isValuePresent(meetingRoomInfo.description) && (
                <Description meetingRoomInfo={meetingRoomInfo} />
              )}
              {isValuePresent(meetingRoomInfo.roomDetails) && (
                <MeetingSpace meetingRoomInfo={meetingRoomInfo} />
              )}
              {(isValuePresent(meetingRoomInfo.amenitiesSet.property)
                || isValuePresent(meetingRoomInfo.amenitiesSet.meetingService)
                || isValuePresent(meetingRoomInfo.amenitiesSet.propertyBusinessService)) && (
                  <Amenities meetingRoomInfo={meetingRoomInfo} />
              )}
              {isValuePresent(meetingRoomInfo.propertyImages) && (
                <Gallery propertyImages={meetingRoomInfo.propertyImages} />
              )}
              {isValuePresent(parseInt(meetingRoomInfo.totalGuestRooms, 10)) && (
                <GuestRooms meetingRoomInfo={meetingRoomInfo} />
              )}
              {property && (
                <Location property={property} />
              )}
            </div>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

export default DetailsModal;
