import React from 'react';
import PropTypes from 'prop-types';

class BackendLink extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    window.location = this.props.path;
  }

  render() {
    const { className, children, linkProps } = this.props;

    return (
      <a className={className} {...linkProps} onClick={this.handleClick}>
        {children}
      </a>
    );
  }
}

BackendLink.propTypes = {
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
  linkProps: PropTypes.object,
};

export default BackendLink;
