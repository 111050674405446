import React from 'react';
import { PulseLoader } from 'react-spinners';

export default function () {
  return (
    <div className="loader-container">
      <PulseLoader color="#73BD5D" size={35} sizeunit="px" />
    </div>
  );
}
