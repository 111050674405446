import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '../../../../../../src/custom/Internationalization';

function Description({ meetingRoomInfo }) {
  return (
    <div id="description" name="amenities" className="meeting_services__section">
      <p className="meeting_services__heading">
        {i18n.t('hotel_card.headings.description')}
      </p>
      <div className="content">
        <div className="tw-flex">
          {meetingRoomInfo.description}
        </div>
      </div>
    </div>
  );
}

Description.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  meetingRoomInfo: PropTypes.object.isRequired,
};

export default Description;
