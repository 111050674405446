/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import HelpIcon from '@mui/icons-material/Help';
import Popup from '../../../../../Common/Popup/Popup';
import AddedActions from '../AddedActions';
import NotAddedActions from '../NotAddedActions';
import { i18n } from '../../../../../../src/custom/Internationalization';

function DisplayRate({
  property,
  showRates,
  handleBookNow,
  propertyResultInfoId,
  handleAddBidRequest,
  handleRemoveBid,
  displayRate,
  displayCurrency,
  canBookNow,
  canEbid,
  isAdded,
  isNegotiated,
  hasPropertiesAvailability,
}) {
  return (
    <>
      {(canEbid && isAdded === true)
        ? <AddedActions handleRemoveBid={handleRemoveBid} propertyId={property.id} />
        : (
          <div className="display-rate-wrapper">
            {hasPropertiesAvailability && displayRate === null && (
              <div className="booking_property_card__price-info tw-text-center">
                <div className="booking_property_card__price-no-rate">{i18n.t('hotel_card.labels.price_on_request')}</div>
              </div>
            )}

            {showRates && hasPropertiesAvailability && displayRate != null && (
              <div className="bid_property_card__price-info tw-text-center">
                {isNegotiated === true && (
                  <div className="booking_property_card__negotiated center tw-mb-2.5">
                    <i className="flag icon tw-mr-2.5" />
                    {i18n.t('hotel_card.labels.negotiated')}
                  </div>
                )}
                <div className="bid_property_card__price-title">
                  {i18n.t('hotel_card.labels.est_nightly_rate')}
                  {canEbid && (
                    <Popup
                      title={i18n.t('hotel_card.messages.est_nightly_rate')}
                      placement="top"
                    >
                      <HelpIcon color="primary" style={{ fontSize: 14 }} />
                    </Popup>
                  )}
                </div>
                <div className="bid_property_card__price-amount">{displayRate}</div>
                <div className="bid_property_card__price-title">{displayCurrency}</div>
              </div>
            )}

            <NotAddedActions
              canBookNow={canBookNow && !isEmpty(displayRate)}
              propertyResultInfoId={propertyResultInfoId}
              handleAddBidRequest={handleAddBidRequest}
              property={property}
              handleBookNow={handleBookNow}
              canEbid={canEbid}
            />
          </div>
        )}
    </>
  );
}

DisplayRate.defaultProps = {
  displayRate: '',
  isNegotiated: false,
  showRates: true,
};

DisplayRate.propTypes = {
  showRates: PropTypes.bool,
  property: PropTypes.shape().isRequired,
  handleBookNow: PropTypes.func.isRequired,
  displayRate: PropTypes.string,
  isAdded: PropTypes.bool.isRequired,
  isNegotiated: PropTypes.bool,
  canBookNow: PropTypes.bool.isRequired,
  canEbid: PropTypes.bool.isRequired,
  hasPropertiesAvailability: PropTypes.bool.isRequired,
  propertyResultInfoId: PropTypes.number.isRequired,
};

export default DisplayRate;
