import React from 'react';
import PropTypes from 'prop-types';

import { i18n } from '../../src/custom/Internationalization';

import Loader from './components/Loader';

import LayoutContainer from './containers/LayoutContainer';
import InstantBookingContainer from './containers/InstantBookingContainer';
import ErrorBoundary from './components/ErrorBoundary';

import AdditionalFilters from './components/AdditionalFilters';
import SearchPropertiesResult from './components/SearchPropertiesResult';
import SearchForm from './components/SearchForm';

function AddBooking({ orgId, eventId, registrationIds }) {
  return (
    <React.Suspense fallback={i18n.t('actions.loading')}>
      <ErrorBoundary>
        <LayoutContainer orgId={orgId} eventId={eventId}>
          {
            ({ loading }) => (
              <div id="bid-request-container" className="event-details">
                {loading && <h1>{i18n.t('actions.loading')}</h1>}
                <InstantBookingContainer orgId={orgId} eventId={eventId} registrationIds={registrationIds}>
                  {
                    ({
                      data: {
                        propertySearch,
                        properties,
                        locationAddress,
                        hasMoreProperties,
                        hasAvailability,
                        lastPage,
                        checkin,
                        checkout,
                        filters,
                        hasUnappliedFilters,
                        hashedMeetingRoomInfoByProp,
                        hashedPropertyImagesByProp,
                        suggestionsByName,
                        filterByName,
                      },
                      handleLocationChange,
                      handleCheckinChange,
                      handleCheckoutChange,
                      handleLoadMoreProperties,
                      handleStarChange,
                      handleChainChange,
                      handlePriceChange,
                      handleGuestRoomsChange,
                      handleMeetingRoomsChange,
                      handleApplyFilters,
                      handleChangeSearch,
                      handleBookNow,
                      handleGetMeetingRoomInfo,
                      handleGetPropertyImages,
                      handleFilterByName,
                      resetFilterByName,
                      handleFetchSuggestions,
                      handleSuggestionSelected,
                      handleSuggestionsClear,
                      setActiveProperty,
                      activePropertyId,
                      isMapView,
                      searchPosition,
                      toggleMapView,
                      loading,
                      notices,
                      handleSorting,
                    }) => (
                      <div>
                        {propertySearch && (
                          <SearchForm
                            eventName={propertySearch.eventName}
                            location={propertySearch.searchLocationAddress}
                            startDate={checkin}
                            endDate={checkout}
                            guestRooms={1}
                            targetAttendance={1}
                            handleLocationChange={handleLocationChange}
                            handleCheckinChange={handleCheckinChange}
                            handleCheckoutChange={handleCheckoutChange}
                            handleChangeSearch={handleChangeSearch}
                            allowDateChange
                          />
                        )}
                        {loading && (<Loader />)}
                        {properties && (
                          <div className="bid_search">
                            <AdditionalFilters
                              onStarChange={handleStarChange}
                              onChainChange={handleChainChange}
                              onPriceChange={handlePriceChange}
                              onGuestRoomsChange={handleGuestRoomsChange}
                              onMeetingRoomsChange={handleMeetingRoomsChange}
                              onApplyFiltersClick={handleApplyFilters}
                              filters={filters}
                              hasUnapplied={hasUnappliedFilters}
                              hasAvailability={hasAvailability}
                            />
                            <SearchPropertiesResult
                              showRates={propertySearch.showRates}
                              suggestionsByName={suggestionsByName}
                              filterByName={filterByName}
                              canEbid={false}
                              canBookNow
                              lastPage={lastPage}
                              properties={properties}
                              locationAddress={locationAddress}
                              hasMoreProperties={hasMoreProperties}
                              hasPropertiesAvailability={hasAvailability}
                              handleLoadMoreProperties={handleLoadMoreProperties}
                              handleBookNow={handleBookNow}
                              handleGetMeetingRoomInfo={handleGetMeetingRoomInfo}
                              handleGetPropertyImages={handleGetPropertyImages}
                              isMapView={isMapView}
                              searchPosition={searchPosition}
                              toggleMapView={toggleMapView}
                              setActiveProperty={setActiveProperty}
                              activePropertyId={activePropertyId}
                              hashedMeetingRoomInfoByProp={hashedMeetingRoomInfoByProp}
                              hashedPropertyImagesByProp={hashedPropertyImagesByProp}
                              handleFilterByName={handleFilterByName}
                              resetFilterByName={resetFilterByName}
                              handleFetchSuggestions={handleFetchSuggestions}
                              handleSuggestionSelected={handleSuggestionSelected}
                              handleSuggestionsClear={handleSuggestionsClear}
                              notices={notices}
                              onSortByChanged={handleSorting}
                            />
                          </div>
                        )}
                      </div>
                    )
                  }
                </InstantBookingContainer>
              </div>
            )
          }
        </LayoutContainer>
      </ErrorBoundary>
    </React.Suspense>
  );
}

AddBooking.propTypes = {
  orgId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  registrationIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AddBooking;
