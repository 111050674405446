import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { ActionCableProvider, ActionCableConsumer } from '@thrash-industries/react-actioncable-provider';
import reduce from 'lodash/reduce';
import clone from 'clone';
import { i18n } from '../../../src/custom/Internationalization';

import {
  orgEventBidGroupPropertyResultsPath,
  orgEventBidGroupsPath,
  orgEventBidGroupDefaultPath,
  orgEventBidGroupBidsPath,
  orgEventEditBidRequest,
  orgEventEditMRFPath,
  orgEventEbidsPath,
} from '../modules/pathHelpers';

import SearchResultsContainerBase from './SearchResultsContainerBase';
import {
  initialFiltersState,
  defaultPriceRangeFilter,
  defaultGuestRoomsRangeFilter,
  defaultMeetingRoomsRangeFilter,
} from '../concerns/AdditionalFilters';

class BidRequestContainer extends SearchResultsContainerBase {
  constructor(props) {
    super(props);

    Object.assign(this.state, {
      bidRequestId: null,
      canBookNow: null,
      bidGroup: null,
      groupedBids: [],
      bids: [],
    });

    this.handleAddBidRequest = this.handleAddBidRequest.bind(this);
    this.handleRemoveBid = this.handleRemoveBid.bind(this);
    this.handleSubmitBid = this.handleSubmitBid.bind(this);
  }

  handleSubmitBid() {
    const { orgId, eventId, fromEdit, newMRF } = this.props;
    const { bidRequestId } = this.state;

    if (newMRF) {
      window.location = orgEventEditMRFPath({ orgId, eventId, bidRequestId});
    } else {
      if (fromEdit) {
        window.location = orgEventEbidsPath({ orgId, eventId });
      } else {
        window.location = orgEventEditBidRequest({ orgId, eventId, bidRequestId });
      }
    }
  }

  handleAddBidRequest(propertyId) {
    const { orgId, eventId, newMRF } = this.props;
    const bidGroupId = this.state.bidGroup.id;
    const url = orgEventBidGroupBidsPath({ orgId, eventId, bidGroupId });

    const options = {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].content,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ propertyId }),
      credentials: 'include',
    };

    fetch(url, options)
      .then(
        (res) => res.json(),
      )
      .then(
        (result) => {
          const { groupedBids } = result;
          this.setState({
            groupedBids,
            bids: this.extractBidsFromResponse(groupedBids, bidGroupId),
          });
        },
        (error) => {
          this.setState({
            loading: false,
            error,
          });
        },
      );
  }

  handleRemoveBid({ propertyId, bidId, bidGroupId }) {
    let bidIdToRemove;
    const currentBidGroupId = bidGroupId || this.state.bidGroup.id;

    if (!bidId) {
      const i = this.state.bids.findIndex(({ property: { id } }) => id === propertyId);

      if (i === -1) {
        console.warn(`Can't delete bid with propertyId=${propertyId}`);
        return;
      }
      bidIdToRemove = this.state.bids[i].id;
    } else {
      bidIdToRemove = bidId;
    }

    const { orgId, eventId } = this.props;
    const url = orgEventBidGroupBidsPath({
      orgId,
      eventId,
      bidGroupId: currentBidGroupId,
    });
    const options = {
      method: 'DELETE',
      headers: {
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].content,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ bidIdToRemove }),
    };

    fetch(url, options)
      .then(
        (res) => res.json(),
      )
      .then(
        (result) => {
          const { groupedBids } = result;
          this.setState({
            groupedBids,
            bids: this.extractBidsFromResponse(groupedBids, currentBidGroupId),
          });
        },
        (error) => {
          this.setState({
            loading: false,
            error,
          });
        },
      );
  }

  handleChangeSearch() {
    const searchParams = {
      location: this.state.location,
      searchChannelName: this.state.searchChannelName,
    };

    this.requestBidGroup(searchParams, true);
  }

  extractBidsFromResponse(groupedBids, searchedBidGroupId) {
    const i = groupedBids.findIndex(({ bidGroupId }) => bidGroupId === searchedBidGroupId);
    return i >= 0 ? groupedBids[i].bids : [];
  }

  requestBidGroup(searchParams, resetFilters) {
    const {
      orgId,
      eventId,
      checkIn,
      checkOut,
    } = this.props;
    let url;
    let method;

    if (searchParams) {
      url = orgEventBidGroupsPath({ orgId, eventId });
      method = 'POST';
    } else {
      url = orgEventBidGroupDefaultPath({
        orgId, eventId, checkIn, checkOut,
      });
      method = 'GET';
    }

    const options = {
      method,
      headers: {
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].content,
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };

    if (searchParams) {
      options.body = JSON.stringify(searchParams);
    }

    const { filters } = this.state;

    fetch(url, options)
      .then(
        (res) => {
          if (res.status === 500) {
            console.log(`Failed to request ${url}: ${res}`);

            this.setState({
              loading: false,
              error: i18n.t('errors.notices.generic_failure'),
            });

            return null;
          }

          if (resetFilters === true) {
            this.setState({
              loading: true,
              filters: clone(initialFiltersState),
              appliedFilters: {},
            });
          } else {
            this.setState({ loading: true });
          }

          return res.json();
        },
      )
      .then(
        (result) => {
          if (!result) {
            return;
          }

          this.setState({
            loading: false,
            lastPage: 1,
            location: result.bidGroup.searchLocationAddress,
            checkin: result.bidGroup.checkin,
            checkout: result.bidGroup.checkout,
            canBookNow: result.canBookNow,
            eventSlug: result.eventSlug,
            bidRequestId: result.bidGroup.bidRequestId,
            bidGroup: result.bidGroup,
            groupedBids: result.groupedBids,
            suggestionsByNameCollection: result.searchPropertiesResult.suggestionsByNameCollection,
            bids: this.extractBidsFromResponse(result.groupedBids, result.bidGroup.id),
            properties: result.searchPropertiesResult.properties,
            hasMoreProperties: result.searchPropertiesResult.hasMoreProperties,
            hasAvailability: result.searchPropertiesResult.hasAvailability,
            searchChannelName: { channel: 'BidRequestChannel', search_id: result.bidGroup.searchId },
            searchPosition: {
              lat: result.bidGroup.searchLat,
              lng: result.bidGroup.searchLng,
            },
            filters: update(filters, {
              $merge: {
                priceRange: {
                  current: { ...defaultPriceRangeFilter },
                  applied: { ...defaultPriceRangeFilter },
                },
                guestRoomsRange: {
                  current: { ...defaultGuestRoomsRangeFilter },
                  applied: { ...defaultGuestRoomsRangeFilter },
                },
                meetingRoomsRange: {
                  current: { ...defaultMeetingRoomsRangeFilter },
                  applied: { ...defaultMeetingRoomsRangeFilter },
                },
              },
            }),
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            loading: false,
            error,
          });
        },
      );
  }

  requestMoreProperties(fullSet = false, newFilters = null, page = null, resultInfoId = null) {
    const { orgId, eventId, newMRF } = this.props;
    const bidGroupId = this.state.bidGroup.id;

    const newPage = page === 0 ? page : (page || (this.state.lastPage + 1));
    const extraParams = (newFilters || this.state.appliedFilters);

    const url = orgEventBidGroupPropertyResultsPath({
      orgId,
      eventId,
      newMRF,
      bidGroupId,
      page: newPage,
      fullSet,
      hideUnavailable: false,
      extraParams,
      resultInfoId,
    });

    const options = {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].content,
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };

    const { sortBy } = this.state;

    fetch(url, options)
      .then(
        (res) => res.json(),
      )
      .then(
        (result) => {
          if (resultInfoId) {
            this.setState({ filteredProperty: result.searchPropertiesResult.properties[0] });
          } else {
            const properties = fullSet === true
              ? result.searchPropertiesResult.properties
              : this.state.properties.concat(result.searchPropertiesResult.properties);

            const sortedProperties = (
              sortBy && this.sortByProperties(sortBy, properties)
            ) || properties;

            const suggestions = fullSet === true
              ? result.searchPropertiesResult.suggestionsByNameCollection
              : this.state.suggestionsByNameCollection.concat(result.searchPropertiesResult.suggestionsByNameCollection);

            this.setState({
              properties: sortedProperties,
              suggestionsByNameCollection: suggestions,
              lastPage: newPage,
              hasMoreProperties: result.searchPropertiesResult.hasMoreProperties,
              hasAvailability: result.searchPropertiesResult.hasAvailability,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            error,
          });
        },
      );
  }

  componentDidMount() {
    this.requestBidGroup();
  }

  render() {
    const { children } = this.props;
    const {
      bidGroup,
      properties,
      hasMoreProperties,
      hasAvailability,
      searchChannelName,
      lastPage,
      loading,
      location,
      checkin,
      checkout,
      error,
      filters,
      groupedBids,
      canBookNow,
      hashedMeetingRoomInfoByProp,
      hashedPropertyImagesByProp,
      filterByName,
      filteredProperty,
      suggestionsByName,
    } = this.state;

    const hasUnappliedFilters = this.hasUnappliedFilters();

    const data = {
      filterByName: filteredProperty === null ? filterByName : filteredProperty.name,
      bidGroup,
      suggestionsByName,
      properties: filteredProperty ? [filteredProperty] : properties,
      hasAvailability,
      hasMoreProperties: filteredProperty ? false : hasMoreProperties,
      location,
      checkin,
      checkout,
      lastPage,
      groupedBids,
      filters: {
        stars: filters.stars.current,
        priceRange: filters.priceRange.current,
        guestRoomsRange: filters.guestRoomsRange.current,
        meetingRoomsRange: filters.meetingRoomsRange.current,
        chains: filters.chains.current,
      },
      hasUnappliedFilters,
      canBookNow: canBookNow
        && reduce(groupedBids, (acc, { bids }) => (acc + bids.length), 0) === 0,
      hashedMeetingRoomInfoByProp,
      hashedPropertyImagesByProp,
    };

    const result = children({
      data,
      loading,
      error,
      handleLoadMoreProperties: this.handleLoadMoreProperties,
      handleAddBidRequest: this.handleAddBidRequest,
      handleRemoveBid: this.handleRemoveBid,
      setActiveProperty: this.setActiveProperty,
      activePropertyId: this.state.activePropertyId,
      handleSorting: this.handleSorting,
      isMapView: this.state.isMapView,
      toggleMapView: this.toggleMapView,
      searchPosition: this.state.searchPosition,
      handleStarChange: this.handleStarChange,
      handleChainChange: this.handleChainChange,
      handlePriceChange: this.handlePriceChange,
      handleGuestRoomsChange: this.handleGuestRoomsChange,
      handleMeetingRoomsChange: this.handleMeetingRoomsChange,
      handleApplyFilters: this.handleApplyFilters,
      handleSubmitBid: this.handleSubmitBid,
      handleLocationChange: this.handleLocationChange,
      handleCheckinChange: this.handleCheckinChange,
      handleCheckoutChange: this.handleCheckoutChange,
      handleChangeSearch: this.handleChangeSearch,
      handleBookNow: this.handleBookNow,
      handleGetMeetingRoomInfo: this.handleGetMeetingRoomInfo,
      handleGetPropertyImages: this.handleGetPropertyImages,
      handleFilterByName: this.handleFilterByName,
      resetFilterByName: this.resetFilterByName,
      handleFetchSuggestions: this.handleFetchSuggestions,
      handleSuggestionSelected: this.handleSuggestionSelected,
      handleSuggestionsClear: this.handleSuggestionsClear,
    });

    if (searchChannelName && hasAvailability === false) {
      return (
        <ActionCableProvider>
          <ActionCableConsumer
            channel={searchChannelName}
            onReceived={this.handleChannelNewMessage}
          />
          {result}
        </ActionCableProvider>
      );
    }

    return result;
  }
}

BidRequestContainer.propTypes = {
  children: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  newMRF: PropTypes.bool.isRequired,
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
};

export default BidRequestContainer;
