/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import ToggleButton from '@mui/material/ToggleButton';
import InputLabel from '@mui/material/InputLabel';
import ButtonGroup from '../../../Common/Form/ButtonGroup/ButtonGroup';
import ColorPicker from '../../../Common/Form/ColorPicker/ColorPicker';
import SliderField from '../../../Common/Form/SliderField/SliderField';
import SwitchField from '../../../Common/Form/SwitchField/SwitchField';
import FilePicker from '../../../Common/Form/FilePicker/FilePicker';
import WYSIWYGEditor from '../../../Common/Form/WYSIWYGEditor/WYSIWYGEditor';
import TextField from '../../../Common/Form/TextField';
import VideoBackground from '../../../Common/VideoBackground/VideoBackground';
import Divider from '../../../Common/Divider/Divider';
import { i18n, i18nARAttr } from '../../../../src/custom/Internationalization';

import {
  ActionBtns,
  ContentWrapper,
  InnerSection,
  FieldWrapper,
  FieldArea,
  PreviewImg,
  FieldsRow,
  RemoveBtn,
} from './styles';

const textFieldData = {
  className: 'form-field',
  variant: 'outlined',
  fullWidth: true,
  nomargin: true,
};

function HeroMainForm({
  filepickerConfigs: {
    apiKey,
    policy,
    signature,
    cname,
  },
  title,
  address,
  textColor,
  bgColor,
  callback,
  byCompany,
  description,
  bgOverlayOpacity,
  bgImage,
  bgVideo,
  showBgImage,
  contentPosition,
  mediaImageUrl,
  mediaVideoUrl,
  showMediaContent,
  mediaBgColor,
  showContent,
  bgHeight,
}) {
  return (
    <ContentWrapper>
      <FieldWrapper>
        <Typography className="field_label" htmlFor="contentPosition">{i18n.t('instant_booking.headings.layout_position')}</Typography>
        <FieldArea>
          <ButtonGroup
            defaultValue={contentPosition}
            exclusive
            onChange={(alignment) => (callback('contentPosition', alignment))}
          >
            <ToggleButton value="left">
              <FormatAlignLeftIcon />
            </ToggleButton>
            <ToggleButton value="center">
              <FormatAlignCenterIcon />
            </ToggleButton>
            <ToggleButton value="right">
              <FormatAlignRightIcon />
            </ToggleButton>
          </ButtonGroup>
        </FieldArea>
      </FieldWrapper>

      <FieldWrapper>
        <Typography className="field_label">{i18n.t('instant_booking.labels.text_layer')}</Typography>
        <FieldArea>
          <InnerSection>
            <SwitchField
              label={i18n.t('instant_booking.labels.text_layer')}
              value={showContent}
              onChange={(value) => (callback('showContent', value))}
            />
          </InnerSection>
          {showContent && (
            <>
              <InnerSection>
                <InputLabel>Text Color</InputLabel>
                <ColorPicker
                  id="text-color-picker"
                  name="textColor"
                  value={textColor}
                  onChange={(color) => (callback('textColor', color))}
                  TextFieldProps={textFieldData}
                />
              </InnerSection>

              <InnerSection>
                <InputLabel>{i18nARAttr('event.name')}</InputLabel>
                <TextField
                  id="title"
                  value={title || ''}
                  onChange={(event) => (callback('title', event.target.value))}
                  nomargin
                  {...textFieldData}
                />
              </InnerSection>

              <InnerSection>
                <InputLabel>{i18nARAttr('event.description')}</InputLabel>
                <WYSIWYGEditor
                  id="event-description-editor"
                  value={description}
                  onChange={(value) => (callback('description', value))}
                />
              </InnerSection>

              <InnerSection>
                <InputLabel>{i18nARAttr('event.location_name')}</InputLabel>
                <TextField
                  id="address"
                  value={address || ''}
                  nomargin
                  onChange={(event) => (callback('address', event.target.value))}
                  {...textFieldData}
                />
              </InnerSection>

              <InnerSection>
                <InputLabel>{i18nARAttr('website.brought_to_you_by')}</InputLabel>
                <TextField
                  id="byCompany"
                  value={byCompany || ''}
                  nomargin
                  onChange={(event) => (callback('byCompany', event.target.value))}
                  {...textFieldData}
                />
              </InnerSection>
            </>
          )}
        </FieldArea>
      </FieldWrapper>

      <FieldWrapper>
        <Typography className="field_label" htmlFor="mediaImageUrl">
          {i18n.t('instant_booking.headings.front_image_layer')}
        </Typography>

        <FieldArea>
          <InnerSection>
            <SwitchField
              label={i18n.t('instant_booking.headings.front_image_layer')}
              value={showMediaContent}
              onChange={(value) => (callback('showMediaContent', value))}
            />
          </InnerSection>

          {showMediaContent && (
            <>
              <InnerSection>
                <InputLabel>{i18nARAttr('event_cover_setting.media_image_url')}</InputLabel>
                <small>{i18n.t('event_cover_setting.messages.min_resolution')}</small>

                {mediaImageUrl && (
                  <PreviewImg img={mediaImageUrl} />
                )}

                {!mediaImageUrl && (
                  <FilePicker
                    apiKey={apiKey}
                    signature={signature}
                    policy={policy}
                    cname={cname}
                    id="mediaImageUrl"
                    label=""
                    onSubmit={(file) => (callback('mediaImageUrl', file))}
                    cropOptions={{ aspectRatio: 400 / 300, force: true }}
                    fileType="image/*"
                    fileUrl={mediaImageUrl}
                    hasWrapper={false}
                    showActionButtons={false}
                  />
                )}

                {mediaImageUrl && (
                  <ActionBtns>
                    <RemoveBtn onClick={() => (callback('mediaImageUrl', null))}>
                      {i18n.t('actions.remove')}
                    </RemoveBtn>
                  </ActionBtns>

                )}
              </InnerSection>

              <InnerSection className="no_border">
                <Divider label={i18n.t('labels.or')} />
                <InputLabel>{i18nARAttr('event_cover_setting.media_video_url')}</InputLabel>

                {mediaVideoUrl && (
                  <PreviewImg img={mediaImageUrl}>
                    <VideoBackground src={mediaVideoUrl} />
                  </PreviewImg>
                )}

                {!mediaVideoUrl && (
                  <FilePicker
                    id="mediaVideoUrl"
                    apiKey={apiKey}
                    signature={signature}
                    policy={policy}
                    cname={cname}
                    label=""
                    onSubmit={(file) => (callback('mediaVideoUrl', file))}
                    fileType="video/*"
                    fileUrl={mediaVideoUrl}
                    hasWrapper={false}
                  />
                )}

                {mediaVideoUrl && (
                  <ActionBtns>
                    <RemoveBtn onClick={() => (callback('mediaVideoUrl', null))}>
                      {i18n.t('actions.remove')}
                    </RemoveBtn>
                  </ActionBtns>
                )}
              </InnerSection>

              <InnerSection>
                <InputLabel>{i18nARAttr('event_cover_setting.media_bg_color')}</InputLabel>
                <ColorPicker
                  id="bg-color-picker"
                  name="bgColor"
                  defaultValue={mediaBgColor}
                  onChange={(color) => (callback('mediaBgColor', color))}
                  TextFieldProps={textFieldData}
                />
              </InnerSection>
            </>
          )}
        </FieldArea>
      </FieldWrapper>

      <FieldWrapper>
        <Typography className="field_label" htmlFor="bgOverlayOpacity">
          {i18nARAttr('event_cover_setting.show_bg_image')}
        </Typography>
        <FieldArea>
          <InnerSection>
            <SwitchField
              label={i18nARAttr('event_cover_setting.show_bg_image')}
              value={showBgImage}
              onChange={(value) => (callback('showBgImage', value))}
            />
          </InnerSection>

          <InnerSection>
            <InputLabel>{i18nARAttr('event_cover_setting.bg_height')}</InputLabel>
            <SliderField
              id="bgHeight"
              defaultValue={bgHeight}
              min={300}
              max={600}
              step={5}
              onChange={(value) => (callback('bgHeight', value))}
            />
          </InnerSection>

          {showBgImage && (
            <>
              <InnerSection>
                <InputLabel>{i18nARAttr('event_cover_setting.bg_overlay_opacity')}</InputLabel>
                <SliderField
                  id="bgOverlayOpacity"
                  defaultValue={bgOverlayOpacity}
                  min={0}
                  max={1}
                  step={0.1}
                  onChange={(value) => (callback('bgOverlayOpacity', value))}
                />
              </InnerSection>

              <InnerSection>
                <InputLabel>{i18nARAttr('website.cover_image_url')}</InputLabel>
                <small>(Min Resolution: 1200 x 300 px)</small>

                {bgImage && (
                  <PreviewImg img={bgImage} />
                )}

                {!bgImage && (
                  <FieldsRow>
                    <FilePicker
                      id="bgImage"
                      apiKey={apiKey}
                      signature={signature}
                      policy={policy}
                      cname={cname}
                      label=""
                      onSubmit={(file) => (callback('bgImage', file))}
                      cropOptions={{ aspectRatio: 1200 / 300, force: true }}
                      fileType="image/*"
                      fileUrl={bgImage}
                      hasWrapper={false}
                    />
                  </FieldsRow>
                )}
                {bgImage && (
                  <ActionBtns>
                    <RemoveBtn onClick={() => (callback('bgImage', null))}>
                      {i18n.t('actions.remove')}
                    </RemoveBtn>
                  </ActionBtns>
                )}
              </InnerSection>

              <InnerSection className="no_border">
                <Divider label="OR" />
                <InputLabel>{i18nARAttr('website.cover_video_url')}</InputLabel>

                {bgVideo && (
                  <PreviewImg img={bgImage}>
                    <VideoBackground src={bgVideo} />
                  </PreviewImg>
                )}

                {!bgVideo && (
                  <FieldsRow>
                    <FilePicker
                      id="bgVideo"
                      apiKey={apiKey}
                      signature={signature}
                      policy={policy}
                      cname={cname}
                      label=""
                      onSubmit={(file) => (callback('bgVideo', file))}
                      fileType="video/*"
                      fileUrl={bgVideo}
                      hasWrapper={false}
                    />
                  </FieldsRow>
                )}
                {bgVideo && (
                  <ActionBtns>
                    <RemoveBtn onClick={() => (callback('bgVideo', null))}>
                      {i18n.t('actions.remove')}
                    </RemoveBtn>
                  </ActionBtns>
                )}
              </InnerSection>
            </>
          )}

          <InnerSection>
            <InputLabel>{i18nARAttr('website.hero_background_color')}</InputLabel>
            <ColorPicker
              name="bgColor"
              defaultValue={bgColor}
              onChange={(color) => (callback('bgColor', color))}
              TextFieldProps={textFieldData}
            />
          </InnerSection>
        </FieldArea>
      </FieldWrapper>
    </ContentWrapper>
  );
}

export default HeroMainForm;
