import styled from 'styled-components';

export const MapContainer = styled.div`
  height: 100%;

  .map {
    min-height: 300px;
    height: 100%;
    width: 100%;
  }
`;
