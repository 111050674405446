/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { i18n } from '../../../../../../src/custom/Internationalization';

class GuestRooms extends Component {
  render() {
    const { totalGuestRooms, totalSingleBed, totalDoubleBed } = this.props.meetingRoomInfo;

    return (
      <div id="guest_room" name="guest_room" className="meeting_services__section">
        <p className="meeting_services__heading">
          {i18n.t('hotel_card.headings.guest_rooms')}
        </p>
        <div className="content">
          <p className="meeting_services__heading_title">
            {i18n.t('hotel_card.headings.guest_rooms')}
          </p>
          <div className="tw-flex">
            <div className="tw-mr-5">
              <p className="meeting_services__stat_text">
                {i18n.t('hotel_card.labels.total_guest_rooms')}
              </p>
              <p className="meeting_services__stat_value">
                {totalGuestRooms}
              </p>
            </div>
            <div className="tw-mr-5">
              <p className="meeting_services__stat_text">
                {i18n.t('hotel_card.labels.singles')}
              </p>
              <p className="meeting_services__stat_value">
                {totalSingleBed}
              </p>
            </div>
            <div className="tw-mr-5">
              <p className="meeting_services__stat_text">
                {i18n.t('hotel_card.labels.doubles')}
              </p>
              <p className="meeting_services__stat_value">
                {totalDoubleBed}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GuestRooms.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  meetingRoomInfo: PropTypes.object.isRequired,
};

export default GuestRooms;
