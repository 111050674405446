import _ from 'lodash';

export function isValuePresent(value) {
  if (value) {
    switch (typeof value) {
      case 'string':
        return value.trim();
      case 'number':
        return value > 0;
      case 'array':
        return value.length > 0;
      case 'object':
        return !_.isEmpty(value);
      default:
        return false;
    }
  } else {
    return false;
  }
}
