import styled, { css } from 'styled-components';
import colors from '../../../Common/constants/colors';
import Button from '../../../Common/Form/Button';

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 1em 0;
`;

export const InnerSection = styled.div`
  margin-bottom: 0;
  padding-bottom: 0;

  small {
    display: flex;
    color: ${colors.grey600};
    margin-bottom: 0.5em;
  }

  label {
    display: block;
    margin-bottom: 0.5em;
  }

  & + &:not(.no_border) {
    border-top: 1px solid ${colors.grey200};
    margin-top: 1em;
    padding-top: 1em;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;
  margin-bottom: 1.5em;

  .form-field {
    background-color: ${colors.white};
    color: #000000;
    border-radius: 5px;
    min-height: 30px;
    position: relative;
  }

  .field_label {
    margin-bottom: 0.3em;
    text-transform: uppercase;
    color: ${colors.grey50};
  }

  ${(props) => props.isShown === false && css`
    display: none;
`};
`;

export const FieldsRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const PreviewImg = styled.div`
  background-color: ${colors.grey50};
  background-size: cover;
  background-position: center;
  border: 1px solid ${colors.grey200};

  position: relative;
  height: 120px;
  width: 100%;
  margin-bottom: 0.5em;

  ${(props) => props.img && css`
    background-image: url("${props.img}");
`};
`;

export const FieldArea = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;

  background-color: white;
  border: 1px solid ${colors.grey200};
  border-radius: 5px;
  padding: 0.5em;

  .label {
    margin-bottom: 0.3em;
  }
`;

export const HeroLayoutOption = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 0 auto;

  background-color: ${colors.grey100};
  border: 1px solid ${colors.grey200};
  border-radius: 3px;

  height: 70px;

  ${(props) => props.flyer && css`
    height: 140px;
`};

  ${(props) => props.bgImg && css`
    background-size: cover;
    background-image: url("${props.bgImg}");
`};
`;

export const ActionBtns = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  justify-content: center;
`;

export const RemoveBtn = styled(Button)`
  background-color: ${colors.red400} !important;
  color: white !important;
  margin-left: 1em !important;
  border: 0 !important;
`;
