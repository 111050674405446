import React from 'react';
import PropTypes from 'prop-types';
import clone from 'clone';
import sortby from 'lodash/sortBy';
import { i18n } from '../../../src/custom/Internationalization';

import {
  orgEventBookNow,
  adminOrgEventBookNow,
  propertyContentPath,
  propertiesImagesInfoPath,
} from '../modules/pathHelpers';

import {
  handleStarChange,
  handlePriceChange,
  handleGuestRoomsChange,
  handleMeetingRoomsChange,
  handleApplyFilters,
  hasUnappliedFilters,
  handleChainChange,
  initialFiltersState,
} from '../concerns/AdditionalFilters';

class SearchResultsContainerBase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterByName: '',
      filteredProperty: null,
      suggestionsByName: null,
      suggestionsByNameCollection: null,
      eventSlug: null,
      location: '',
      properties: null,
      locationAddress: '',
      hasMoreProperties: true,
      hasAvailability: true,
      searchChannelName: { channel: 'BidRequestChannel', search_id: null },
      searchPosition: { lat: 0, lng: 0 },
      lastPage: 1,
      activePropertyId: null,
      isMapView: false,
      filters: clone(initialFiltersState),
      appliedFilters: {},
      hashedMeetingRoomInfoByProp: {},
      hashedPropertyImagesByProp: {},
      sortBy: null,
      loading: null,
      error: null,
    };

    this.handleLoadMoreProperties = this.handleLoadMoreProperties.bind(this);
    this.handleChannelNewMessage = this.handleChannelNewMessage.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.setActiveProperty = this.setActiveProperty.bind(this);
    this.toggleMapView = this.toggleMapView.bind(this);
    this.handleStarChange = handleStarChange.bind(this);
    this.handlePriceChange = handlePriceChange.bind(this);
    this.handleGuestRoomsChange = handleGuestRoomsChange.bind(this);
    this.handleMeetingRoomsChange = handleMeetingRoomsChange.bind(this);
    this.handleApplyFilters = handleApplyFilters.bind(this);
    this.hasUnappliedFilters = hasUnappliedFilters.bind(this);
    this.handleChainChange = handleChainChange.bind(this);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.handleBookNow = this.handleBookNow.bind(this);
    this.handleGetMeetingRoomInfo = this.handleGetMeetingRoomInfo.bind(this);
    this.handleGetPropertyImages = this.handleGetPropertyImages.bind(this);
    this.handleFilterByName = this.handleFilterByName.bind(this);
    this.resetFilterByName = this.resetFilterByName.bind(this);
    this.handleFetchSuggestions = this.handleFetchSuggestions.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.handleSuggestionsClear = this.handleSuggestionsClear.bind(this);
    this.handleSorting = this.handleSorting.bind(this);
  }

  sortByProperties(sortBy, properties, ignorePriceless) {
    return sortby(properties, (p) => {
      switch (sortBy) {
        case 'near': return p.distanceInMeters;
        case 'far': return -p.distanceInMeters;
        case 'lowest': return p.displayRate ? Number(p.displayRate.replace(/[^0-9.-]+/g, '')) : 100000;
        case 'highest': return -(p.displayRate ? Number(p.displayRate.replace(/[^0-9.-]+/g, '')) : 0);
        case 'preferred': return p.preferred === true && (ignorePriceless || p.displayRate !== null) ? (-p.id) : p.id;
        case 'negotiated': return p.isNegotiated === true && p.displayRate !== null ? (-p.id) : p.id;
        case 'negotiated&preferred': return (p.isNegotiated === true || p.preferred === true) && (ignorePriceless || p.displayRate !== null) ? (-p.id) : p.id;
      }
    });
  }

  handleFilterByName(value, method) {
    if (value) {
      this.setState({ filterByName: value });
    }
  }

  resetFilterByName() {
    this.setState({ filterByName: '' });
  }

  handleSuggestionSelected(event, {
    suggestion,
    suggestionValue,
    suggestionIndex,
    sectionIndex,
    method,
  }) {
    if (suggestion === i18n.t('instant_booking.venue_options.all_venues')) {
      this.setState({ filteredProperty: null, suggestionsByName: [], filterByName: '' });
      return;
    }

    const i = this.state.properties.findIndex((p) => p.id === suggestion.id);

    if (i < 0) {
      const fullSet = false;
      const newFilters = null;
      const page = null;
      const forceHideUnavailable = false;

      if (this.state.bids) { // Check to know if it's instant booking or ebids
        this.requestMoreProperties(fullSet, newFilters, page, suggestion.id);
      } else {
        this.requestMoreProperties(fullSet, newFilters, page, forceHideUnavailable, suggestion.id);
      }
    } else {
      this.setState({ filteredProperty: this.state.properties[i] });
    }
  }

  handleSuggestionsClear() {
    this.setState({ suggestionsByName: [] });
  }

  handleFetchSuggestions({ value }) {
    const updatedSuggestionsByName = this.state.suggestionsByNameCollection.filter(({ name }) => name.toLowerCase().includes(value.toLowerCase()));

    updatedSuggestionsByName.unshift('All Properties');
    this.setState({ suggestionsByName: updatedSuggestionsByName });
  }

  handleLocationChange(location) {
    this.setState({ location });
  }

  handleBookNow(propertyResultInfoId) {
    const { orgId, registrationIds, isEventBookingPages } = this.props;
    const { eventSlug, propertySearchId } = this.state;
    let url;

    if (isEventBookingPages === true) {
      url = orgEventBookNow({
        orgId, eventSlug, propertyResultInfoId, searchId: propertySearchId,
      });
    } else {
      url = adminOrgEventBookNow({
        orgId, eventSlug, propertyResultInfoId, registrationIds,
      });
    }

    window.location = url;
  }

  toggleMapView(value) {
    const { showFilter } = this.state;

    const comingFromMapView = this.state.isMapView && !value;
    this.setState({
      isMapView: value,
      showFilter: value ? !value : comingFromMapView || showFilter,
    });
  }

  setActiveProperty(propertyID) {
    const { activePropertyId } = this.state;
    const newPropertyId = (activePropertyId === propertyID) ? null : propertyID;
    this.setState({ activePropertyId: newPropertyId });
    if (newPropertyId !== null) {
      const propertyList = document.getElementById('properties_scroll');
      const propertyItem = document.getElementById(`property_${newPropertyId}`);
      propertyList.scrollTop = propertyItem.offsetTop - propertyList.offsetTop;
    }
  }

  handleChannelNewMessage(msg) {
    if (msg.status === 'finished') {
      this.requestMoreProperties(true, null, this.state.lastPage);
    }
  }

  handleLoadMoreProperties() {
    this.requestMoreProperties(false);
  }

  handleGetMeetingRoomInfo(propertyId, propertyContentId) {
    const url = propertyContentPath({ propertyContentId });
    const options = {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].content,
        'Content-Type': 'application/json',
      },
    };

    fetch(url, options)
      .then(
        (res) => res.json(),
      )
      .then(
        (result) => {
          const { roomDetails } = result;

          const meetingRoomInfo = { roomDetails: roomDetails || [], ...result };
          const newData = {};
          newData[propertyId] = meetingRoomInfo;
          const hashedMeetingRoomInfoByProp = { ...this.state.hashedMeetingRoomInfoByProp, ...newData };
          this.setState({ hashedMeetingRoomInfoByProp });
        },
        (error) => {
          this.setState({
            loading: false,
            error,
          });
        },
      );
  }

  handleGetPropertyImages(propertyId, propertyContentId) {
    const url = propertiesImagesInfoPath({ propertyContentId });
    const options = {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].content,
        'Content-Type': 'application/json',
      },
    };

    fetch(url, options)
      .then(
        (res) => res.json(),
      )
      .then(
        (result) => {
          const newData = {};
          newData[propertyId] = result;
          const hashedPropertyImagesByProp = { ...this.state.hashedPropertyImagesByProp, ...newData };
          this.setState({ hashedPropertyImagesByProp });
        },
        (error) => {
          this.setState({
            loading: false,
            error,
          });
        },
      );
  }

  handleSorting(sortBy, ignorePriceless) {
    const properties = this.sortByProperties(sortBy, this.state.properties, ignorePriceless);

    this.setState({ properties, sortBy });
  }
}

SearchResultsContainerBase.propTypes = {
  children: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  newMRF: PropTypes.bool.isRequired,
  registrationIds: PropTypes.arrayOf(PropTypes.string),
  checkIn: PropTypes.string,
  checkOut: PropTypes.string,
};

export default SearchResultsContainerBase;
