import React from 'react';
import PropTypes from 'prop-types';

import AccordionItem from './AccordionItem';
import IconCheckbox from '../../../../Common/Form/IconCheckbox/IconCheckbox';
import { i18n } from '../../../../../src/custom/Internationalization';

function StarRating({
  active, stars, onStarChange,
}) {
  return (
    <AccordionItem title={i18n.t('instant_booking.filters.star_rating')} active={active}>
      <div className="tw-flex tw-justify-center">
        {
          Object.keys(stars).map((star) => (<IconCheckbox id={`rating_${star}_star`} index={star} key={star} checked={stars[star]} onClick={onStarChange} />))
        }
      </div>
    </AccordionItem>
  );
}

StarRating.propTypes = {
  onStarChange: PropTypes.func.isRequired,
  stars: PropTypes.object.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default StarRating;
