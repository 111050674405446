import React from 'react';
import PropTypes from 'prop-types';

function Notice(props) {
  const {
    notices,
    serverity,
  } = props;

  return (
    <div className="tw-ml-8 tw-mb-0">
      {
        notices.map((notice) => (
          <div className={`ui ${serverity} icon message`}>
            <i className={`${notice.icon} icon`} />
            <div className="content">
              <p>
                {notice.text}
              </p>
            </div>
          </div>
        ))
      }
    </div>
  );
}

Notice.defaultProps = {
  notices: [],
  serverity: 'info',
};

Notice.propTypes = {
  notices: PropTypes.array.isRequired,
  serverity: PropTypes.string,
};

export default Notice;
