import reduce from 'lodash/reduce';
import { isEmpty } from 'lodash/fp';

export const orgEventAdminMenuPath = ({ orgId, eventId }) => {
  const aid = localStorage.getItem('aid');
  if (aid === null) {
    return `/api/admin/organizations/${orgId}/events/${eventId}/menu`;
  }

  return `/api/admin/organizations/${orgId}/events/${eventId}/menu?aid=${aid}`;
};

export const orgEventPropertySearchesPath = ({ orgId, eventId }) => `/api/admin/organizations/${orgId}/events/${eventId}/property_searches`;

export const orgEventPropertySearchPath = ({
  orgId, eventId, propertySearchId, page, fullSet, hideUnavailable, extraParams, resultInfoId,
}) => {
  let url = `/api/admin/organizations/${orgId}/events/${eventId}/property_searches/${propertySearchId}?page=${page}`;

  if (fullSet) {
    url += `&full_set=${fullSet}`;
  }

  if (hideUnavailable) {
    url += `&hide_unavailable=${hideUnavailable}`;
  }

  if (extraParams) {
    url = reduce(extraParams, (url, value, name) => {
      if (isEmpty(value)) {
        return url;
      }
      return `${url}&${name}=${value}`;
    }, url);
  }

  if (resultInfoId) {
    url += `&result_info_id=${resultInfoId}`;
  }

  return url;
};

export const orgEventBidGroupDefaultPath = ({
  orgId, eventId, forceSearch, checkIn, checkOut,
}) => `/api/admin/organizations/${orgId}/events/${eventId}/bid_request/groups/default?forceSearch=${forceSearch}&check_in=${checkIn}&check_out=${checkOut}`;

export const orgEventBidGroupsPath = ({ orgId, eventId, forceSearch }) => `/api/admin/organizations/${orgId}/events/${eventId}/bid_request/groups?forceSearch=${forceSearch}`;

export const orgEventBidGroupPropertyResultsPath = ({
  orgId, eventId, bidGroupId, page, fullSet, hideUnavailable, extraParams, resultInfoId,
}) => {
  let url = `/api/admin/organizations/${orgId}/events/${eventId}/bid_groups/${bidGroupId}/property_results?page=${page}`;

  if (fullSet) {
    url += `&full_set=${fullSet}`;
  }

  if (hideUnavailable) {
    url += `&hide_unavailable=${hideUnavailable}`;
  }

  if (extraParams) {
    url = reduce(extraParams, (url, value, name) => {
      if (isEmpty(value)) {
        return url;
      }
      return `${url}&${name}=${value}`;
    }, url);
  }

  if (resultInfoId) {
    url += `&result_info_id=${resultInfoId}`;
  }

  return url;
};

export const orgEventBidGroupBidsPath = ({ orgId, eventId, bidGroupId }) => {
  const url = `/api/admin/organizations/${orgId}/events/${eventId}/bid_groups/${bidGroupId}/bids`;

  return url;
};

export const orgEventEditBidRequest = ({ orgId, eventId, bidRequestId }) => {
  const url = `/organizations/${orgId}/events/${eventId}/bid_requests/${bidRequestId}/edit`;

  return url;
};

export const orgEventEditMRFPath = ({ orgId, eventId, bidRequestId }) => {
  const url = `/organizations/${orgId}/events/${eventId}/meeting_request_forms/${bidRequestId}/edit`;

  return url;
};

export const orgEventEbidsPath = ({ orgId, eventId }) => {
  const url = `/organizations/${orgId}/events/${eventId}/ebids#/bid_details`;

  return url;
};

export const orgEventBookNow = ({
  orgId,
  eventSlug,
  propertyResultInfoId,
  searchId,
}) => (`/organizations/${orgId}/events/${eventSlug}/searches/${searchId}/results/${propertyResultInfoId}`);

export const adminOrgEventBookNow = ({
  orgId, eventSlug, propertyResultInfoId, registrationIds,
}) => {
  let url = `/organizations/${orgId}/events/${eventSlug}/admin/search_results/${propertyResultInfoId}`;

  if (registrationIds) {
    url += `?registration_ids=${registrationIds}`;
  }

  return url;
};

export const propertyContentPath = ({ propertyContentId }) => `/api/property_contents/${propertyContentId}`;
export const propertiesImagesInfoPath = ({ propertyContentId }) => `/api/property_contents/${propertyContentId}/images`;

export const eventHeaderSectionPath = ({ eventId }) => `/api/event_header_sections/${eventId}`;

export const orgEventUpdateCoverPath = ({ orgId, eventId }) => `/api/admin/organizations/${orgId}/events/${eventId}/update_cover`;
