import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { i18n } from '../../../../../src/custom/Internationalization';

const ImgThumb = styled.div`
  position: absolute;
  top: 0;
  right:0;
  bottom: 0;
  left: 0;
  background-color: transparent;

  ${(props) => props.image && `
    background-image: url("${props.image}"), url("${props.fallbackImage}");
    background-size: cover;
    background-position: center;
  `}
`;

function SelectedProperty({
  propertyName,
  bidId,
  bidGroupId,
  propertyImg,
  fallbackImage,
  propertyId,
  handleRemoveBid,
}) {
  return (
    <div className="bid_selected_property" id={`property-${propertyId}`}>
      <div className="bid_property_card__small_image">
        {propertyImg !== '' && <ImgThumb image={propertyImg} fallbackImage={fallbackImage} />}
        <i className="icon home" />
      </div>
      <p className="bid_selected_property__name tw-ml-4">{propertyName}</p>
      <button
        className="ui button groupize tw-ml-auto"
        onClick={() => {
          handleRemoveBid({ bidId, bidGroupId });
        }}
      >
        {i18n.t('actions.remove')}
      </button>
    </div>
  );
}

SelectedProperty.propTypes = {
  propertyName: PropTypes.string.isRequired,
  bidId: PropTypes.number.isRequired,
  bidGroupId: PropTypes.number.isRequired,
  propertyId: PropTypes.number.isRequired,
  handleRemoveBid: PropTypes.func.isRequired,
};

export default SelectedProperty;
