import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AlertMessage from '../../Common/AlertMessage/AlertMessage';
import { orgEventUpdateCoverPath } from '../modules/pathHelpers';
import HeroMainForm from '../components/HeroMainForm';
import { i18n } from '../../../src/custom/Internationalization';

const filepickerFieldNames = ['mediaImageUrl', 'mediaVideoUrl', 'bgImage', 'bgVideo'];

class HeroSectionContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSuccessAlertOpen: false,
      formValues: {
        title: props.title,
        address: props.address,
        byCompany: props.byCompany,
        description: props.description || '',
        startDate: props.startDate,
        startTime: props.startTime || '',
        endDate: props.endDate,
        endTime: props.endTime || '',
        bgColor: props.bgColor,
        textColor: props.textColor,
        bgHeight: props.bgHeight || 300,
        bgImage: props.bgImage,
        bgVideo: props.bgVideo,
        mediaImageUrl: props.mediaImageUrl,
        mediaVideoUrl: props.mediaVideoUrl,
        showDates: props.showDates || false,
        showTimes: props.showTimes || false,
        bgOverlayOpacity: props.bgOverlayOpacity === null ? 0.4 : parseFloat(props.bgOverlayOpacity),
        heroType: props.heroType || 'media',
        contentPosition: props.contentPosition || 'left',
        mediaBgColor: props.mediaBgColor || '#eeeeee',
        showBgImage: props.showBgImage === null ? true : props.showBgImage,
        showMediaContent: props.showMediaContent === null ? false : props.showMediaContent,
        showContent: props.showContent === null ? true : props.showContent,
      },
    };
    this.onChangeConfig = this.onChangeConfig.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.requestCoverUpdate = this.requestCoverUpdate.bind(this);
    this.closeSuccessAlert = this.closeSuccessAlert.bind(this);
  }

  onChangeConfig(newConfig) {
    const { formValues } = this.state;
    this.setState({
      formValues: Object.assign(formValues, newConfig),
    });
  }

  onChangeField(fieldName, value) {
    const { filepickerConfigs: { policy, signature } } = this.props;
    let newState = value;

    if (_.includes(filepickerFieldNames, fieldName)) {
      if (_.isArray(value) && value.length > 0) {
        newState = `${value[0].url}?policy=${policy}&signature=${signature}`;
      } else if (value != null) {
        return;
      }
    }

    const { formValues } = this.state;
    formValues[fieldName] = newState;
    this.setState({ formValues });
  }

  requestCoverUpdate() {
    const { orgId, eventId } = this.props;
    const { formValues } = this.state;
    const url = orgEventUpdateCoverPath({ orgId, eventId });
    const method = 'POST';

    const options = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cover: formValues,
        event_id: eventId,
      }),
    };

    fetch(url, options)
      .then(
        (res) => res.json(),
      )
      .then(
        ({ cover }) => {
          this.setState({ isSuccessAlertOpen: true });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => { },
      );
  }

  closeSuccessAlert() {
    this.setState({ isSuccessAlertOpen: false });
  }

  render() {
    const { children, filepickerConfigs } = this.props;
    const { formValues } = this.state;

    const settingsData = {
      title: i18n.t('instant_booking.headings.update_cover'),
      innerTitle: i18n.t('instant_booking.headings.layouts'),
      mainForm: <HeroMainForm
        {...formValues}
        filepickerConfigs={filepickerConfigs}
        callback={this.onChangeField}
      />,
      handleSave: this.requestCoverUpdate,
    };

    return (
      <>
        <AlertMessage
          open={this.state.isSuccessAlertOpen}
          variant="success"
          duration={3000}
          message={i18n.t('notices.generic_save')}
          handleClose={this.closeSuccessAlert}
        />
        {
          children({ attrs: formValues, settingsData })
        }
      </>
    );
  }
}

HeroSectionContainer.propTypes = {
  children: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  filepickerConfigs: PropTypes.shape().isRequired,
};

export default HeroSectionContainer;
