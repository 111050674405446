import React from 'react';
import PropTypes from 'prop-types';
import PhotoSlider from '../../../../../Common/PhotoSlider/PhotoSlider';
import { i18n } from '../../../../../../src/custom/Internationalization';

function Gallery({ propertyImages }) {
  return (
    <div id="image_gallery" name="image_gallery" className="meeting_services__section">
      <p className="meeting_services__heading">{i18n.t('hotel_card.headings.image_gallery')}</p>
      <div className="content">
        <PhotoSlider images={propertyImages} showTitles />
      </div>
    </div>
  );
}

Gallery.propTypes = {
  propertyImages: PropTypes.array.isRequired,
};

export default Gallery;
