import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { i18n } from '../../../../../../src/custom/Internationalization';

function NotAddedActions({
  property,
  handleBookNow,
  handleAddBidRequest,
  propertyResultInfoId,
  canBookNow,
  canEbid,
}) {
  return (
    <div className="bid_property_card__price-info action_btns tw-text-center">
      {canBookNow && (
        <button
          className="ui icon labeled basic primary mini button tw-uppercase"
          onClick={() => { handleBookNow(propertyResultInfoId); }}
        >
          <Icon name="tags" />
          {i18n.t('hotel_card.actions.book_now')}
        </button>
      )}

      {canBookNow
        && canEbid && (
          <div className="ui horizontal divider">{i18n.t('labels.or')}</div>
        )}

      {canEbid && (
        <button
          className="ui icon labeled groupize mini button tw-uppercase"
          onClick={() => { handleAddBidRequest(property.id); }}
        >
          <Icon name="envelope outline" />
          {i18n.t('hotel_card.actions.add_to_list')}
        </button>
      )}
    </div>
  );
}

NotAddedActions.propTypes = {
  property: PropTypes.object.isRequired,
  canBookNow: PropTypes.bool.isRequired,
  canEbid: PropTypes.bool.isRequired,
  handleBookNow: PropTypes.func,
  propertyResultInfoId: PropTypes.number.isRequired,
};

export default NotAddedActions;
