import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Icon, Tab } from 'semantic-ui-react';
import { FaInfoCircle, FaCheckCircle } from 'react-icons/fa';
import reduce from 'lodash/reduce';
import TabItem from './TabItem';
import { FlexFlow, ColorButton } from '../../../Common/helpers/DisplayHelpers';
import { i18n } from '../../../../src/custom/Internationalization';

function CartFooter({
  groupedBids,
  handleRemoveBid,
  onSubmitBid,
  ebidsMinimumPolicy,
}) {
  const [showContent, setShowContent] = useState(false);
  const [initHotelCount, setInitHotelCount] = useState(false);

  const setCartVisibility = (count) => {
    if (count <= 0 && showContent) {
      onToggleContent();
    }
  };

  useEffect(() => {
    const count = reduce(groupedBids, (acc, { bids }) => (acc + bids.length), 0);
    setInitHotelCount(count);
    setCartVisibility(count);
  }, [groupedBids]);

  useEffect(() => {
    const count = reduce(groupedBids, (acc, { bids }) => (acc + bids.length), 0);
    setInitHotelCount(count);
  }, [groupedBids]);

  const onToggleContent = () => {
    setShowContent(!showContent);
  };

  const panes = groupedBids.map(({ location, bids, bidGroupId }) => {
    if (bids.length === 0) {
      return {};
    }

    return {
      menuItem: { key: bidGroupId, icon: 'map marker alternate', content: location },
      render: () => (
        <TabItem.Pane
          key={bidGroupId}
          bidGroupId={bidGroupId}
          bids={bids}
          handleRemoveBid={handleRemoveBid}
        />
      ),
    };
  });

  const addedHotelsCount = reduce(groupedBids, (acc, { bids }) => (acc + bids.length), 0);
  const isLoaded = initHotelCount !== addedHotelsCount;
  const showClass = showContent ? 'show' : '';
  const showLabel = showContent ? i18n.t('actions.hide') : i18n.t('actions.show');
  const showIconClass = showContent ? 'eye slash' : 'eye';
  const highlightClass = isLoaded ? ` active_${(addedHotelsCount % 2) + 1}` : '';

  const footerMessageCondition = (ebidsMinimumPolicy > addedHotelsCount);

  return (
    <section className={`bid_search_footer ${showClass}`}>
      <div className="bid_search_footer__top">
        <div className="bid_search_footer__breadcrumbs">
          <Icon name="envelope outline" className="big bid_search_footer__icon" />
          <p className="bid_search_footer__title">
            <span className="tw-hidden lg:tw-inline">
              {i18n.t('event_request_form.messages.request_bids')}
            </span>
            <Icon name="chevron right" className="tw-hidden lg:tw-inline" />
            <span className={`ui circular label number${highlightClass}`}>
              {addedHotelsCount}
            </span>
            <span className="tw-hidden lg:tw-inline">
              {i18n.t('event_request_form.messages.venues_added')}
            </span>
          </p>
        </div>

        <div className="bid_search_footer__message_area">
          {
            ebidsMinimumPolicy > 1
            && addedHotelsCount > 0
            && (
              <div className={`bid_search_footer__message ${footerMessageCondition ? 'warning' : 'success'}`}>
                <FlexFlow align="center" splitSpace="1em">
                  {footerMessageCondition && (
                    <>
                      <FaInfoCircle />
                      <p
                        // eslint-disable-next-line react/no-danger
                        // eslint-disable-next-line max-len
                        dangerouslySetInnerHTML={{ __html: i18n.t('ebids.notices.bid_policy', { added_count: addedHotelsCount, min_count: ebidsMinimumPolicy }) }}
                      />
                    </>
                  )}

                  {ebidsMinimumPolicy <= addedHotelsCount && (
                    <>
                      <FaCheckCircle />
                      <p
                        // eslint-disable-next-line react/no-danger
                        // eslint-disable-next-line max-len
                        dangerouslySetInnerHTML={{ __html: i18n.t('ebids.notices.bid_policy_success', { added_count: addedHotelsCount, min_count: ebidsMinimumPolicy }) }}
                      />
                    </>
                  )}
                </FlexFlow>
              </div>
            )
          }
        </div>

        {addedHotelsCount > 0 && (
          <div className="bid_search_footer__actions--mobile">
            <FlexFlow splitSpace="1em">
              <ColorButton variant="outlined" onClick={onToggleContent}>
                <Icon name={showIconClass} />
              </ColorButton>

              <ColorButton
                onClick={onSubmitBid}
                disabled={footerMessageCondition}
              >
                <Icon name="arrow circle right" />
              </ColorButton>
            </FlexFlow>
          </div>
        )}

        {addedHotelsCount > 0 && (
          <div className="bid_search_footer__actions">
            <FlexFlow splitSpace="1em">
              <ColorButton
                variant="outlined"
                size="large"
                onClick={onToggleContent}
              >
                {i18n.t('ebids.labels.added_venues', { count: showLabel })}
              </ColorButton>

              <ColorButton
                size="large"
                onClick={onSubmitBid}
              >
                {i18n.t('actions.continue')}
              </ColorButton>
            </FlexFlow>
          </div>
        )}
      </div>

      <div className="bid_search_footer__content">
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      </div>
    </section>
  );
}

CartFooter.defaultProps = {
  ebidsMinimumPolicy: 0,
};

CartFooter.propTypes = {
  groupedBids: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleRemoveBid: PropTypes.func.isRequired,
  onSubmitBid: PropTypes.func.isRequired,
  ebidsMinimumPolicy: PropTypes.number,
};

export default CartFooter;
