import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import Button from '@mui/material/Button';
import LocationField from '../../../Common/Form/LocationField/LocationField';
import DateField from '../../../Common/Form/DateField/DateField';
import NumberField from '../../../Common/Form/NumberField';

import colors from '../../../Common/constants/colors';
import { i18n, i18nARAttr } from '../../../../src/custom/Internationalization';

const FormArea = styled.div`
  background-color: ${colors.grey100};
  border-bottom: 1px solid ${colors.grey200};
  padding: 0.5em 1em;
`;

const FormAreaWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  max-width: 1024px;
  margin: auto;

  @media (max-width: 992px) {
    flex-flow: column nowrap;
    flex: 1 0 auto;
  }

  .groupize-button {
    background-color: ${colors.groupizeBrand};
    box-shadow: none;
    color: ${colors.white};
    margin: 0.5em;

    &:hover {
      background-color: ${colors.groupizeBrand};
    }

    &:active {
      box-shadow: none;
    }
  }
`;

function SimpleSearchForm({
  location_address,
  startDate: initStartDate,
  endDate: initEndDate,
  guestRooms,
  disabled,
  maxRoomsNumber,
  handleCheckinChange,
  handleCheckoutChange,
  handleLocationChange,
  handleGuestRoomsChange,
  handleChangeSearch,
}) {
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);

  useEffect(() => {
    setEndDate(initEndDate);
  }, [initEndDate]);

  useEffect(() => {
    setStartDate(initStartDate);
  }, [initStartDate]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    handleCheckinChange(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    handleCheckoutChange(date);
  };

  useEffect(() => {
    const currentDay = moment(startDate);
    if (currentDay.isAfter(endDate) || !moment(endDate).isValid()) {
      currentDay.add(1, 'd');
      handleEndDateChange(currentDay.format('YYYY-MM-DD'));
    }
  }, [startDate]);

  return (
    <FormArea>
      <FormAreaWrapper>
        <LocationField
          id="locationAddress"
          label={i18nARAttr('property_search.location_address')}
          address={location_address}
          onLocationSelected={(address, LatLng) => handleLocationChange(address)}
        />
        <DateField
          id="eventStartDate"
          label={i18nARAttr('property_search.checkin')}
          value={startDate}
          minDate={moment().format()}
          onDateSelected={handleStartDateChange}
          isDisabled={false}
          minheight="20px"
        />
        <DateField
          id="eventEndDate"
          label={i18nARAttr('property_search.checkout')}
          minDate={(startDate && moment(startDate).add(1, 'days').format('YYYY-MM-DD')) || moment().format()}
          value={endDate}
          onDateSelected={handleEndDateChange}
          isDisabled={false}
          minheight="20px"
        />
        <NumberField
          id="number"
          label={i18nARAttr('property_search.number_of_rooms')}
          defaultNum={guestRooms}
          onChanged={handleGuestRoomsChange}
          max={maxRoomsNumber}
          minheight="20px"
        />
        <Button
          size="large"
          color="primary"
          variant="contained"
          className="groupize-button"
          disabled={disabled}
          onClick={handleChangeSearch}
        >
          {i18n.t('actions.search')}
        </Button>
      </FormAreaWrapper>
    </FormArea>
  );
}

SimpleSearchForm.defaultProps = {
  location_address: '',
  startDate: '',
  maxRoomsNumber: null,
};

SimpleSearchForm.propTypes = {
  location_address: PropTypes.string,
  startDate: PropTypes.string,
  guestRooms: PropTypes.number.isRequired,
  maxRoomsNumber: PropTypes.number,
};

export default SimpleSearchForm;
