import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '../../../../src/custom/Internationalization';

function AlreadyRegisteredAction({ orgId, eventId, actionText }) {
  return (
    <div className="tw-ml-4">
      <a className="ui blue labeled icon button" href={`/organizations/${orgId}/events/${eventId}/request_access`}>
        <i className="user icon" />
        {actionText}
      </a>
    </div>
  );
}

function LinkToSkip({ orgId, eventId, registration: { id }}) {
  return (
    <div className="ml-1">
      <a className="ui blue basic button" href={`/organizations/${orgId}/events/${eventId}/registrations/${id}/summaries/${id}/edit`}>
        {i18n.t("actions.skip")}
      </a>
    </div>
  );
}

function LinkToSummary({ orgId, eventId, registration: { id, fullName } }) {
  return (
    <div className="tw-ml-4">
      <a className="ui labeled icon blue basic button" href={`/organizations/${orgId}/events/${eventId}/registrations/${id}/summaries/${id}/edit`}>
        <i className="icon user" />
        {fullName}
      </a>
    </div>
  );
}

function LinkToRemoveSession({ orgId, eventId, removeAttendeeSession }) {
  return (
    <div className="tw-ml-4">
      <button onClick={() => removeAttendeeSession({ orgId, eventId })} className="ui blue labeled icon button">
        <i className="sign out icon" />
        {i18n.t('actions.logout')}
      </button>
    </div>
  );
}

function EventHeader({
  logoUrl, orgId, eventId, alreadyRegistered, registration, removeAttendeeSession, roomBookingMandatory
}) {
  return (
    <section className="event-website__header">
      <div className="ui container">
        <div className="booking_header tw-px-4">
          <div className="booking_header__item--logo">
            <a href={`/organizations/${orgId}/events/${eventId}`}>
              <img src={logoUrl} alt="Missing logo" />
            </a>
          </div>
          <div className="booking_header__item--actions">
            {registration === null && (
              <AlreadyRegisteredAction orgId={orgId} eventId={eventId} actionText={alreadyRegistered} />
            )}
            {registration !== null && !roomBookingMandatory && (
              <LinkToSkip orgId={orgId} eventId={eventId} registration={registration} />
            )}
            {registration !== null && (
              <>
                <LinkToSummary orgId={orgId} eventId={eventId} registration={registration} />
                <LinkToRemoveSession orgId={orgId} eventId={eventId} removeAttendeeSession={removeAttendeeSession} />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

EventHeader.defaultProps = {
  registration: {},
  roomBookingMandatory: true,
};

EventHeader.propTypes = {
  logoUrl: PropTypes.string.isRequired,
  orgId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  alreadyRegistered: PropTypes.string.isRequired,
  registration: PropTypes.shape(),
  removeAttendeeSession: PropTypes.func.isRequired,
  roomBookingMandatory: PropTypes.bool,
};

export default EventHeader;
