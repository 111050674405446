import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ImgThumb = styled.div`
  position: absolute;
  top: 0;
  right:0;
  bottom: 0;
  left: 0;
  background-color: transparent;

  ${(props) => props.image && `
    background-image: url("${props.image}"), url("${props.fallbackImage}");
    background-size: cover;
    background-position: center;
  `}
`;

function LogoBlock({ image, fallbackImage }) {
  return (
    <div className="bid_property_card__image">
      {image !== '' && <ImgThumb image={image} fallbackImage={fallbackImage} />}
      {(!image || image === '') && <i className="icon home" />}
    </div>
  );
}

LogoBlock.propTypes = {
  image: PropTypes.string.isRequired,
  fallbackImage: PropTypes.string,
};

export default LogoBlock;
