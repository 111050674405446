import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  Icon,
} from 'semantic-ui-react';
import AccordionItem from './AccordionItem';
import { i18n } from '../../../../src/custom/Internationalization';

function AdditionalFilters({
  show,
  onStarChange,
  onChainChange,
  onPriceChange,
  onGuestRoomsChange,
  onMeetingRoomsChange,
  filters,
  hasUnapplied,
  onApplyFiltersClick,
  hasAvailability,
}) {
  const [showFilter, setShowFilter] = useState(show);
  const [accordions, setAccordions] = useState({
    0: true,
    1: false,
    2: false,
    3: false,
  });

  useEffect(() => {
    if (show) {
      const { innerWidth: width } = window;
      setShowFilter((width > 990));
    }
  }, [show]);

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    setAccordions(accordions.map((a, i) => (i === index ? a : !a)));
  };

  const filterClass = showFilter ? '' : 'collapsed';
  const iconName = showFilter ? 'chevron left' : 'options';

  return (
    <div className={`bid_search__filter ${filterClass}`}>
      <div className="bid_sidebar ui system fluid card">
        <div className="content toggle-title-area tw-flex tw-flex-nowrap tw-p-0">
          <div className="title-block toggle_content">
            <div className="filter icon">
              <Icon name="options" className="tw-mr-4" />
              {i18n.t('actions.filter')}
            </div>
          </div>
          <div className="title-block toggler">
            <div
              className="bid_search__filter-toggler filter icon"
              onClick={() => setShowFilter(!showFilter)}
            >
              <Icon name={iconName} className="tw-m-0" />
            </div>
          </div>
        </div>

        <div className="content tw-p-0 toggle_content">
          {hasUnapplied
            && (
              <div className="tw-p-4">
                <button
                  type="button"
                  className="ui groupize fluid button tw-uppercase"
                  onClick={onApplyFiltersClick}
                >
                  {i18n.t('actions.apply')}
                </button>
              </div>
            )}
          <Accordion fluid styled className="vertical">
            <AccordionItem.StarRating
              active
              onClick={handleAccordionClick}
              stars={filters.stars}
              onStarChange={onStarChange}
            />

            <AccordionItem.PricePerNight
              active
              onPriceChange={onPriceChange}
              value={filters.priceRange}
              enabled={hasAvailability}
            />
            <AccordionItem.GuestRooms
              active
              onGuestRoomsChange={onGuestRoomsChange}
              value={filters.guestRoomsRange}
            />
            <AccordionItem.MeetingRooms
              active
              onMeetingRoomsChange={onMeetingRoomsChange}
              value={filters.meetingRoomsRange}
            />
            <AccordionItem.Chain
              active
              onClick={handleAccordionClick}
              chains={filters.chains}
              onChainChange={onChainChange}
            />
          </Accordion>
        </div>
      </div>
    </div>
  );
}

AdditionalFilters.defaultProps = {
  show: false,
};

AdditionalFilters.propTypes = {
  show: PropTypes.bool,
  onStarChange: PropTypes.func.isRequired,
  onChainChange: PropTypes.func.isRequired,
  onPriceChange: PropTypes.func.isRequired,
  onGuestRoomsChange: PropTypes.func.isRequired,
  onMeetingRoomsChange: PropTypes.func.isRequired,
  filters: PropTypes.shape().isRequired,
  hasUnapplied: PropTypes.bool.isRequired,
  onApplyFiltersClick: PropTypes.func.isRequired,
  hasAvailability: PropTypes.bool.isRequired,
};

export default AdditionalFilters;
