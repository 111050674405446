import React from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import clone from 'clone';
import { v4 as uuidv4 } from 'uuid';
import $ from 'jquery';
import consumer from '../../Common/Consumer';
import { i18n } from '../../../src/custom/Internationalization';

import {
  orgEventPropertySearchesPath,
  orgEventPropertySearchPath,
} from '../modules/pathHelpers';

import SearchResultsContainerBase from './SearchResultsContainerBase';
import {
  initialFiltersState,
  defaultPriceRangeFilter,
  defaultGuestRoomsRangeFilter,
  defaultMeetingRoomsRangeFilter,
} from '../concerns/AdditionalFilters';

class InstantBookingContainer extends SearchResultsContainerBase {
  constructor(props) {
    super(props);

    const defaultSearchParams = { location_address: '', checkin: null, checkout: null };
    const propertySearchParams = JSON.parse(localStorage.getItem('propertySearchParams')) || defaultSearchParams;

    localStorage.removeItem('propertySearchParams');

    Object.assign(this.state, {
      checkin: props.presetDates ? propertySearchParams.checkin : null,
      checkout: props.presetDates ? propertySearchParams.checkout : null,
      propertySearch: null,
      propertySearchId: null,
      presetSearch: props.presetSearch,
      presetDates: props.presetDates,
      presetLocation: props.presetLocation,
      locationAddress: props.presetLocation ? propertySearchParams.location_address : null,
      searchGuid: uuidv4(),
    });

    this.handleCheckinChange = this.handleCheckinChange.bind(this);
    this.handleCheckoutChange = this.handleCheckoutChange.bind(this);
  }

  handleCheckinChange(checkin) {
    this.setState({ checkin });
  }

  handleCheckoutChange(checkout) {
    this.setState({ checkout });
  }

  handleChannelNewMessage(msg) {
    if (msg.status === 'id_assigned' && msg.property_search_id !== this.state.propertySearchId) {
      Object.assign(this.state, { propertySearchId: msg.property_search_id });
    }

    if (msg.status === 'finished' && msg.property_search_id === this.state.propertySearchId) {
      this.requestMoreProperties(true, null, this.state.lastPage, true);
    }
  }

  handleChangeSearch() {
    this.setState({ loading: true });
    this.requestNewAvailability(true);
  }

  searchParamsValid(searchParams) {
    if (this.state.presetSearch) {
      return true;
    }

    return !((!this.state.presetDates
      && (!searchParams.checkin || !searchParams.checkout))
      || (!this.state.presetLocation && !searchParams.location));
  }

  connectActionCableSubscription() {
    consumer.subscriptions.create({
      channel: 'BidRequestChannel',
      search_id: this.state.searchGuid,
    }, {
      received: (data) => this.handleChannelNewMessage(data),
    });
  }

  requestNewAvailability(resetProperties = false) {
    const searchParams = {
      location: this.state.location,
      checkin: this.state.checkin,
      checkout: this.state.checkout,
      searchChannelName: { channel: 'BidRequestChannel', search_id: this.state.searchGuid },
    };

    const { orgId, eventId } = this.props;

    this.setState({ hasAvailability: false, loading: true });

    if (resetProperties) {
      this.setState({
        properties: null,
        hasMoreProperties: true,
        lastPage: 1,
        activePropertyId: null,
        hashedPropertyImagesByProp: {},
        hashedMeetingRoomInfoByProp: {},
      });
    }

    if (this.searchParamsValid(searchParams)) {
      $('#enter-search-header')
        .addClass('hidden');
      this.setState({
        loading: null,
        error: null,
      });
    } else {
      $('#enter-search-header')
        .removeClass('hidden');
      this.setState({
        loading: false,
        error: 'missing search terms',
      });
      return;
    }

    const url = orgEventPropertySearchesPath({ orgId, eventId });

    const options = {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].content,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchParams),
    };

    fetch(url, options)
      .then(
        (res) => {
          this.setState({ loading: true, filters: clone(initialFiltersState), appliedFilters: {} });
          return res.json();
        },
      )
      .then(
        (result) => {
          if (result.error) {
            this.setState({ loading: false, error: result.error });
            return;
          }
          this.setState({
            eventSlug: result.eventSlug,
            propertySearch: result.propertySearch,
            propertySearchId: result.propertySearch.id,
            checkin: result.propertySearch.checkin,
            checkout: result.propertySearch.checkout,
            locationAddress: result.propertySearch.searchLocationAddress,
            loading: false,
            lastPage: 1,
            properties: result.searchPropertiesResult.properties,
            hasMoreProperties: result.searchPropertiesResult.hasMoreProperties,
            hasAvailability: result.searchPropertiesResult.hasAvailability,
            notices: result.notices,
            searchPosition: {
              lat: result.propertySearch.searchLat,
              lng: result.propertySearch.searchLng,
            },
            filters: update(this.state.filters, {
              $merge: {
                priceRange: {
                  current: { ...defaultPriceRangeFilter },
                  applied: { ...defaultPriceRangeFilter },
                },
                guestRoomsRange: {
                  current: { ...defaultGuestRoomsRangeFilter },
                  applied: { ...defaultGuestRoomsRangeFilter },
                },
                meetingRoomsRange: {
                  current: { ...defaultMeetingRoomsRangeFilter },
                  applied: { ...defaultMeetingRoomsRangeFilter },
                },
              },
            }),
          });
        },
      );
  }

  requestMoreProperties(fullSet = false, newFilters = null, page = null, forceHideUnavailable = false, resultInfoId = null) {
    const { orgId, eventId } = this.props;
    const { propertySearchId } = this.state;

    const newPage = page === 0 ? page : (page || (this.state.lastPage + 1));
    const extraParams = (newFilters || this.state.appliedFilters);
    const hideUnavailable = this.state.hasAvailability || forceHideUnavailable;

    const url = orgEventPropertySearchPath({
      orgId, eventId, propertySearchId, page: newPage, fullSet, hideUnavailable, extraParams, resultInfoId,
    });
    const options = {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.querySelectorAll('meta[name="csrf-token"]')[0].content,
        'Content-Type': 'application/json',
      },
    };

    fetch(url, options)
      .then(
        (res) => res.json(),
      )
      .then(
        (result) => {
          if (resultInfoId) {
            this.setState({ filteredProperty: result.searchPropertiesResult.properties[0] });
          } else {
            const properties = fullSet
              ? result.searchPropertiesResult.properties
              : this.state.properties.concat(result.searchPropertiesResult.properties);
            const sortedProperties = (this.state.sortBy && this.sortByProperties(this.state.sortBy, properties)) || properties;
            this.setState({
              properties: sortedProperties,
              lastPage: newPage,
              hasMoreProperties: result.searchPropertiesResult.hasMoreProperties,
              hasAvailability: result.searchPropertiesResult.hasAvailability,
              suggestionsByNameCollection: result.searchPropertiesResult.suggestionsByNameCollection,
            });
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({ error });
        },
      );
  }

  componentDidMount() {
    const { presetSearch } = this.props;

    this.connectActionCableSubscription();

    if (presetSearch) {
      this.requestNewAvailability();
    }
  }

  componentWillUnmount() {
    try {
      consumer.disconnect();
    } catch (e) { /* ignore AC unmount failure */ }
  }

  render() {
    const { children } = this.props;
    const {
      propertySearch,
      properties,
      locationAddress,
      hasMoreProperties,
      hasAvailability,
      lastPage,
      loading,
      location,
      checkin,
      checkout,
      error,
      notices,
      filters,
      hashedMeetingRoomInfoByProp,
      hashedPropertyImagesByProp,
      filterByName,
      filteredProperty,
      suggestionsByName,
    } = this.state;

    const hasUnappliedFilters = this.hasUnappliedFilters();

    const data = {
      propertySearch,
      filterByName: !filteredProperty ? filterByName : filteredProperty.name,
      suggestionsByName,
      properties: filteredProperty ? [filteredProperty] : properties,
      locationAddress,
      hasAvailability,
      hasMoreProperties: filteredProperty ? false : hasMoreProperties,
      location,
      checkin,
      checkout,
      lastPage,
      filters: {
        stars: filters.stars.current,
        priceRange: filters.priceRange.current,
        guestRoomsRange: filters.guestRoomsRange.current,
        meetingRoomsRange: filters.meetingRoomsRange.current,
        chains: filters.chains.current,
      },
      hasUnappliedFilters,
      hashedMeetingRoomInfoByProp,
      hashedPropertyImagesByProp,
    };

    const result = children({
      data,
      loading,
      error,
      notices,
      handleSorting: this.handleSorting,
      handleLoadMoreProperties: this.handleLoadMoreProperties,
      setActiveProperty: this.setActiveProperty,
      activePropertyId: this.state.activePropertyId,
      isMapView: this.state.isMapView,
      toggleMapView: this.toggleMapView,
      searchPosition: this.state.searchPosition,
      handleStarChange: this.handleStarChange,
      handleChainChange: this.handleChainChange,
      handlePriceChange: this.handlePriceChange,
      handleGuestRoomsChange: this.handleGuestRoomsChange,
      handleMeetingRoomsChange: this.handleMeetingRoomsChange,
      handleApplyFilters: this.handleApplyFilters,
      handleLocationChange: this.handleLocationChange,
      handleCheckinChange: this.handleCheckinChange,
      handleCheckoutChange: this.handleCheckoutChange,
      handleChangeSearch: this.handleChangeSearch,
      handleBookNow: this.handleBookNow,
      handleGetMeetingRoomInfo: this.handleGetMeetingRoomInfo,
      handleGetPropertyImages: this.handleGetPropertyImages,
      handleFilterByName: this.handleFilterByName,
      resetFilterByName: this.resetFilterByName,
      handleFetchSuggestions: this.handleFetchSuggestions,
      handleSuggestionSelected: this.handleSuggestionSelected,
      handleSuggestionsClear: this.handleSuggestionsClear,
    });

    return (
      <React.Suspense fallback={i18n.t('actions.loading')}>
        {result}
      </React.Suspense>
    );
  }
}

InstantBookingContainer.defaultProps = {
  isEventBookingPages: false,
  presetSearch: true,
  presetDates: true,
  presetLocation: true,
};

InstantBookingContainer.propTypes = {
  children: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  isEventBookingPages: PropTypes.bool,
  presetSearch: PropTypes.bool,
  presetDates: PropTypes.bool,
  presetLocation: PropTypes.bool,
};

export default InstantBookingContainer;
