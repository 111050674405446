import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '../../src/custom/Internationalization';
import LayoutContainer from './containers/LayoutContainer';
import BidRequestContainer from './containers/BidRequestContainer';
import ErrorBoundary from './components/ErrorBoundary';

import Loader from './components/Loader';
import AdditionalFilters from './components/AdditionalFilters';
import SearchPropertiesResult from './components/SearchPropertiesResult';
import SearchForm from './components/SearchForm';
import CartFooter from './components/CartFooter';

function BidRequest({
  orgId, eventId, fromEdit, newMRF, ebidsMinimumPolicy, startDate, endDate,
}) {
  const getBidsForGroup = (properties, groupedBids, bidGroup) => {
    const i = properties && groupedBids.findIndex(({ bidGroupId }) => bidGroupId === bidGroup.id);
    return (i === null || i < 0) ? [] : groupedBids[i].bids;
  };

  return (
    <React.Suspense fallback={i18n.t('actions.loading')}>
      <ErrorBoundary>
        <LayoutContainer orgId={orgId} eventId={eventId}>
          {
            ({ loading }) => (
              <div id="bid-request-container" className="event-details">
                {loading && (<h1>{i18n.t('actions.loading')}</h1>)}
                <BidRequestContainer
                  fromEdit={fromEdit}
                  newMRF={newMRF}
                  orgId={orgId}
                  eventId={eventId}
                  checkIn={startDate}
                  checkOut={endDate}
                >
                  {
                    ({
                      data: {
                        bidGroup,
                        properties,
                        hasMoreProperties,
                        hasAvailability,
                        lastPage,
                        location,
                        checkin,
                        checkout,
                        groupedBids,
                        filters,
                        hasUnappliedFilters,
                        canBookNow,
                        hashedMeetingRoomInfoByProp,
                        hashedPropertyImagesByProp,
                        suggestionsByName,
                        filterByName,
                      },
                      error,
                      handleLocationChange,
                      handleCheckinChange,
                      handleCheckoutChange,
                      handleLoadMoreProperties,
                      handleAddBidRequest,
                      handleRemoveBid,
                      handleStarChange,
                      handleChainChange,
                      handlePriceChange,
                      handleGuestRoomsChange,
                      handleMeetingRoomsChange,
                      handleApplyFilters,
                      handleSubmitBid,
                      handleChangeSearch,
                      handleBookNow,
                      handleGetMeetingRoomInfo,
                      handleGetPropertyImages,
                      handleFilterByName,
                      resetFilterByName,
                      handleFetchSuggestions,
                      handleSuggestionSelected,
                      handleSuggestionsClear,
                      handleSorting,
                      setActiveProperty,
                      activePropertyId,
                      isMapView,
                      searchPosition,
                      toggleMapView,
                      resultsLoading = loading,
                    }) => {
                      const bidsForCurrentBidGroup = getBidsForGroup(properties, groupedBids, bidGroup);

                      return (
                        <>
                          {resultsLoading && <Loader />}
                          {!resultsLoading && error && (
                            <div className="ui negative message">
                              <div className="header">
                                {i18n.t('errors.notices.search_failed')}
                              </div>
                              <p>
                                {error}
                              </p>
                            </div>
                          )}
                          {bidGroup
                            && (
                              <SearchForm
                                eventName={bidGroup.eventName}
                                location={location}
                                startDate={startDate || checkin}
                                endDate={endDate || checkout}
                                guestRooms={bidGroup.numberOfRooms}
                                targetAttendance={bidGroup.targetAttendance}
                                handleLocationChange={handleLocationChange}
                                handleCheckinChange={handleCheckinChange}
                                handleCheckoutChange={handleCheckoutChange}
                                handleChangeSearch={handleChangeSearch}
                                allowDateChange={false}
                              />
                            )}
                          {properties
                            && (
                              <div className="bid_search">
                                <AdditionalFilters
                                  show
                                  onStarChange={handleStarChange}
                                  onChainChange={handleChainChange}
                                  onPriceChange={handlePriceChange}
                                  onGuestRoomsChange={handleGuestRoomsChange}
                                  onMeetingRoomsChange={handleMeetingRoomsChange}
                                  onApplyFiltersClick={handleApplyFilters}
                                  filters={filters}
                                  hasUnapplied={hasUnappliedFilters}
                                  hasAvailability={hasAvailability}
                                />
                                <SearchPropertiesResult
                                  onSortByChanged={handleSorting}
                                  suggestionsByName={suggestionsByName}
                                  filterByName={filterByName}
                                  canBookNow={canBookNow}
                                  canEbid
                                  lastPage={lastPage}
                                  bids={bidsForCurrentBidGroup}
                                  properties={properties}
                                  locationAddress={bidGroup.searchLocationAddress}
                                  hasMoreProperties={hasMoreProperties}
                                  hasPropertiesAvailability={hasAvailability}
                                  handleLoadMoreProperties={handleLoadMoreProperties}
                                  handleBookNow={handleBookNow}
                                  handleAddBidRequest={handleAddBidRequest}
                                  handleRemoveBid={handleRemoveBid}
                                  handleGetMeetingRoomInfo={handleGetMeetingRoomInfo}
                                  handleGetPropertyImages={handleGetPropertyImages}
                                  isMapView={isMapView}
                                  searchPosition={searchPosition}
                                  toggleMapView={toggleMapView}
                                  setActiveProperty={setActiveProperty}
                                  activePropertyId={activePropertyId}
                                  hashedMeetingRoomInfoByProp={hashedMeetingRoomInfoByProp}
                                  hashedPropertyImagesByProp={hashedPropertyImagesByProp}
                                  handleFilterByName={handleFilterByName}
                                  resetFilterByName={resetFilterByName}
                                  handleFetchSuggestions={handleFetchSuggestions}
                                  handleSuggestionSelected={handleSuggestionSelected}
                                  handleSuggestionsClear={handleSuggestionsClear}
                                />
                              </div>
                            )}
                          {bidGroup
                            && (
                              <CartFooter
                                groupedBids={groupedBids}
                                handleRemoveBid={handleRemoveBid}
                                location={bidGroup.searchLocationAddress}
                                onSubmitBid={handleSubmitBid}
                                ebidsMinimumPolicy={ebidsMinimumPolicy}
                              />
                            )}
                        </>
                      );
                    }
                  }
                </BidRequestContainer>
              </div>
            )
          }
        </LayoutContainer>
      </ErrorBoundary>
    </React.Suspense>
  );
}

BidRequest.defaultProps = {
  ebidsMinimumPolicy: 0,
  startDate: null,
  endDate: null,
};

BidRequest.propTypes = {
  orgId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  fromEdit: PropTypes.bool.isRequired,
  newMRF: PropTypes.bool.isRequired,
  ebidsMinimumPolicy: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default BidRequest;
