/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isValuePresent } from '../../../../modules/isValuePresent';
import { i18n } from '../../../../../../src/custom/Internationalization';

class Amenities extends Component {
  render() {
    const { meetingRoomInfo: { amenitiesSet } } = this.props;
    return (
      <div id="amenities" name="amenities" className="meeting_services__section">
        <p className="meeting_services__heading">
          {i18n.t('hotel_card.headings.amenities_and_services')}
        </p>
        <div className="content">
          <div className="tw-flex">
            {
              isValuePresent(amenitiesSet.property)
              && (
                <div className="meeting_services__amenities_list">
                  <p className="meeting_services__heading_title">
                    {i18n.t('hotel_card.headings.amenities')}
                  </p>
                  <ul id="amenities_list" className="show_all">
                    {amenitiesSet.property.map((a, i) => (
                      <li key={i}>
                        {' '}
                        {a}
                        {' '}
                      </li>
                    ))}
                  </ul>
                </div>
              )
            }

            {
              isValuePresent(amenitiesSet.meetingService)
              && (
                <div className="meeting_services__amenities_list">
                  <p className="meeting_services__heading_title">{i18n.t('hotel_card.headings.meeting_services')}</p>
                  <ul id="meeting_services_list" className="show_all">
                    {amenitiesSet.meetingService.map((a, i) => (
                      <li key={i}>
                        {' '}
                        {a}
                        {' '}
                      </li>
                    ))}
                  </ul>
                </div>
              )
            }

            {
              isValuePresent(amenitiesSet.propertyBusinessService)
              && (
                <div className="meeting_services__amenities_list">
                  <p className="meeting_services__heading_title">{i18n.t('hotel_card.headings.business_services')}</p>
                  <ul id="business_services_list" className="show_all">
                    {amenitiesSet.propertyBusinessService.map((a, i) => (
                      <li key={i}>
                        {' '}
                        {a}
                        {' '}
                      </li>
                    ))}
                  </ul>
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

Amenities.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  meetingRoomInfo: PropTypes.object.isRequired,
};

export default Amenities;
