import React from "react";
import PropTypes from "prop-types";
import { Icon, Button, Dropdown } from "semantic-ui-react";

import Filters from "./Filters";
import PropertyList from "./PropertyList";
import PropertyMap from "./PropertyMap/PropertyMap";
import Notice from "../Notice";
import { i18n } from "../../../../src/custom/Internationalization";

const sortOptions = [
  {
    text: "",
    value: "",
    content: <i> none </i>,
  },
  {
    text: i18n.t("instant_booking.sort_options.distance_near"),
    value: "near",
    content: (
      <p className="item_text">
        {i18n.t("instant_booking.sort_options.distance_near")}
      </p>
    ),
  },
  {
    text: i18n.t("instant_booking.sort_options.distance_far"),
    value: "far",
    content: (
      <p className="item_text">
        {i18n.t("instant_booking.sort_options.distance_far")}
      </p>
    ),
  },
  {
    text: i18n.t("instant_booking.sort_options.price_lowest"),
    value: "lowest",
    content: (
      <p className="item_text">
        {i18n.t("instant_booking.sort_options.price_lowest")}
      </p>
    ),
  },
  {
    text: i18n.t("instant_booking.sort_options.price_highest"),
    value: "highest",
    content: (
      <p className="item_text">
        {i18n.t("instant_booking.sort_options.price_highest")}
      </p>
    ),
  },
  {
    text: i18n.t("instant_booking.sort_options.preferred"),
    value: "preferred",
    content: (
      <p className="item_text">
        {i18n.t("instant_booking.sort_options.preferred")}
      </p>
    ),
  },
  {
    text: i18n.t("instant_booking.sort_options.negotiated"),
    value: "negotiated",
    content: (
      <p className="item_text">
        {i18n.t("instant_booking.sort_options.negotiated")}
      </p>
    ),
  },
  {
    text: i18n.t("instant_booking.sort_options.negotiated_and_preferred"),
    value: "negotiated&preferred",
    content: (
      <p className="item_text">
        {i18n.t("instant_booking.sort_options.negotiated_and_preferred")}
      </p>
    ),
  },
];

function SearchPropertiesResult({
  showRates,
  properties,
  hasMoreProperties,
  handleLoadMoreProperties,
  handleGetMeetingRoomInfo,
  handleGetPropertyImages,
  hasPropertiesAvailability,
  handleAddBidRequest,
  handleBookNow,
  handleFilterByName,
  resetFilterByName,
  handleFetchSuggestions,
  handleSuggestionSelected,
  handleSuggestionsClear,
  lastPage,
  locationAddress,
  handleRemoveBid,
  setActiveProperty,
  activePropertyId,
  bids,
  isMapView,
  toggleMapView,
  searchPosition,
  canBookNow,
  canEbid,
  hashedMeetingRoomInfoByProp,
  hashedPropertyImagesByProp,
  filterByName,
  suggestionsByName,
  isEventBookingPages,
  onSortByChanged,
  notices,
}) {
  const ignorePriceless = isEventBookingPages === false;

  return (
    <div className="bid_search__results" id="page_content">
      {properties.length > 0 && (
        <div className="filters_area">
          <Icon name="search" />
          <Filters
            handleFetchSuggestions={handleFetchSuggestions}
            handleSuggestionSelected={handleSuggestionSelected}
            handleSuggestionsClear={handleSuggestionsClear}
            suggestionsByName={suggestionsByName || properties}
            filterByName={filterByName}
            handleFilterByName={handleFilterByName}
            resetFilterByName={resetFilterByName}
          />
          <div className="sort_area">
            <span>{i18n.t("instant_booking.labels.sort_by")}</span>
            <Dropdown
              inline
              direction="left"
              options={sortOptions}
              onChange={(e, { value }) =>
                onSortByChanged(value, ignorePriceless)
              }
            />
          </div>
        </div>
      )}

      <div className="tw-mx-8 tw-my-4">
        <Button.Group basic>
          <a
            className={`ui button${!isMapView ? " active" : ""}`}
            onClick={() => {
              toggleMapView(false);
            }}
          >
            <Icon name="list" />
            {i18n.t("instant_booking.labels.list_view")}
          </a>
          <a
            className={`ui button tw-hidden min-[993px]:tw-flex${isMapView ? " active" : ""}`}
            onClick={() => {
              toggleMapView(true);
            }}
          >
            <Icon name="map" />
            {i18n.t("instant_booking.labels.map_view")}
          </a>
        </Button.Group>
      </div>

      {notices && <Notice notices={notices} serverity="warning" />}

      {properties.length > 0 && (
        <p className="tw-ml-8 tw-mb-0">
          {i18n.t("instant_booking.labels.viewing_venues", {
            count: properties.length,
          })}

          <a
            className="ui groupize link label"
            onClick={handleLoadMoreProperties}
          >
            <i className="plus icon" />
            {i18n.t("instant_booking.actions.view_more")}
          </a>
        </p>
      )}

      <div className="bid_properties">
        <PropertyList
          showRates={showRates}
          properties={properties}
          hashedMeetingRoomInfoByProp={hashedMeetingRoomInfoByProp}
          hashedPropertyImagesByProp={hashedPropertyImagesByProp}
          hasMoreProperties={hasMoreProperties}
          handleGetMeetingRoomInfo={handleGetMeetingRoomInfo}
          handleGetPropertyImages={handleGetPropertyImages}
          handleLoadMoreProperties={handleLoadMoreProperties}
          hasPropertiesAvailability={hasPropertiesAvailability}
          bids={bids}
          lastPage={lastPage}
          handleAddBidRequest={handleAddBidRequest}
          handleBookNow={handleBookNow}
          handleRemoveBid={handleRemoveBid}
          locationAddress={locationAddress}
          isMapView={isMapView}
          setActiveProperty={setActiveProperty}
          activePropertyId={activePropertyId}
          canBookNow={canBookNow}
          canEbid={canEbid}
          isEventBookingPages={isEventBookingPages}
        />

        {isMapView && (
          <PropertyMap
            showRates={showRates}
            searchPosition={searchPosition}
            properties={properties}
            bidsToBeCreated={bids}
            setActiveProperty={setActiveProperty}
            activePropertyId={activePropertyId}
            hasPropertiesAvailability={hasPropertiesAvailability}
            handleAddBidRequest={handleAddBidRequest}
            handleBookNow={handleBookNow}
            handleRemoveBid={handleRemoveBid}
            canBookNow={canBookNow}
            canEbid={canEbid}
          />
        )}
      </div>
    </div>
  );
}

SearchPropertiesResult.defaultProps = {
  showRates: true,
  isEventBookingPages: false,
  properties: [],
  notices: [],
};

SearchPropertiesResult.propTypes = {
  showRates: PropTypes.bool,
  properties: PropTypes.arrayOf(PropTypes.shape()),
  hasMoreProperties: PropTypes.bool.isRequired,
  handleLoadMoreProperties: PropTypes.func.isRequired,
  handleGetMeetingRoomInfo: PropTypes.func.isRequired,
  handleGetPropertyImages: PropTypes.func.isRequired,
  hasPropertiesAvailability: PropTypes.bool.isRequired,
  handleAddBidRequest: PropTypes.func.isRequired,
  handleBookNow: PropTypes.func.isRequired,
  handleFilterByName: PropTypes.func.isRequired,
  resetFilterByName: PropTypes.func.isRequired,
  handleFetchSuggestions: PropTypes.func.isRequired,
  handleSuggestionSelected: PropTypes.func.isRequired,
  handleSuggestionsClear: PropTypes.func.isRequired,
  lastPage: PropTypes.number.isRequired,
  locationAddress: PropTypes.string.isRequired,
  handleRemoveBid: PropTypes.func.isRequired,
  setActiveProperty: PropTypes.func.isRequired,
  activePropertyId: PropTypes.number,
  bids: PropTypes.arrayOf(PropTypes.shape()),
  isMapView: PropTypes.bool.isRequired,
  toggleMapView: PropTypes.func.isRequired,
  searchPosition: PropTypes.shape().isRequired,
  canBookNow: PropTypes.bool.isRequired,
  canEbid: PropTypes.bool.isRequired,
  hashedMeetingRoomInfoByProp: PropTypes.shape().isRequired,
  hashedPropertyImagesByProp: PropTypes.shape().isRequired,
  filterByName: PropTypes.string.isRequired,
  suggestionsByName: PropTypes.arrayOf(PropTypes.shape()),
  isEventBookingPages: PropTypes.bool,
  onSortByChanged: PropTypes.func.isRequired,
  notices: PropTypes.array,
};

export default SearchPropertiesResult;
