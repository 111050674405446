import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import AccordionItem from './AccordionItem';
import { i18n } from '../../../../../src/custom/Internationalization';

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

function PricePerNight({
  active,
  value,
  onPriceChange,
}) {
  return (
    <AccordionItem title={i18n.t('instant_booking.filters.price_per_night')} active={active}>
      <div className="rc-slider-wrapper tw-p-4">
        <Range
          min={0}
          max={1000}
          step={10}
          defaultValue={[0, 1000]}
          marks={{ 0: 0, 1000: '1000+' }}
          value={[value.min, value.max]}
          onChange={(v) => { onPriceChange({ min: v[0], max: v[1] }); }}
          tipFormatter={(v) => (v >= 1000 ? '1000+' : v)}
        />
      </div>
    </AccordionItem>
  );
}

PricePerNight.defaultProps = {
  active: false,
};

PricePerNight.propTypes = {
  active: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]).isRequired,
  onPriceChange: PropTypes.func.isRequired,
};

export default PricePerNight;
