import React from 'react';
import PropTypes from 'prop-types';
import DisplayRate from './DisplayRate';
import { i18n } from '../../../../../src/custom/Internationalization';

function PriceBlock(props) {
  const {
    hasPropertiesAvailability, showRates, isMapView, isAdded, className,
  } = props;
  let addClasses = className + (isMapView ? ' map-view' : '');
  addClasses += isAdded ? ' added' : '';
  return (
    <div className={`bid_property_card__price-block ${addClasses}`}>
      {!hasPropertiesAvailability && (
        <div className="pending-rate">
          <div className="bid_property_card__price-info tw-text-center tw-mb-4">
            <i className="spinner loading icon" />
          </div>

          <p className="property_card__price-title tw-mb-4">{i18n.t('hotel_card.actions.searching_prices')}</p>
        </div>
      )}
      <DisplayRate
        showRates={showRates}
        {...props}
      />
    </div>
  );
}

PriceBlock.defaultProps = {
  className: '',
  showRates: true,
};

PriceBlock.propTypes = {
  handleBookNow: PropTypes.func.isRequired,
  showRates: PropTypes.bool,
  hasPropertiesAvailability: PropTypes.bool.isRequired,
  className: PropTypes.string,
  displayRate: PropTypes.string,
  displayCurrency: PropTypes.string,
  isNegotiated: PropTypes.bool,
  isAdded: PropTypes.bool.isRequired,
  canBookNow: PropTypes.bool.isRequired,
};

export default PriceBlock;
