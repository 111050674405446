import React from 'react';
import PropTypes from 'prop-types';

import { eventHeaderSectionPath } from '../modules/pathHelpers';

class EventHeaderSectionContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: null,
      error: null,
      heroSection: null,
      headerMenu: null,
      event: null,
      isAuthenticated: false,
      registration: null,
    };

    this.removeAttendeeSession = this.removeAttendeeSession.bind(this);
  }

  componentDidMount() {
    const { eventId } = this.props;

    fetch(eventHeaderSectionPath({ eventId }), {})
      .then(
        (res) => {
          this.setState({ loading: true });
          return res.json();
        },
      )
      .then(
        (result) => {
          this.setState({
            loading: false,
            heroSection: result.heroSection,
            event: result.event,
            headerMenu: result.headerMenu,
            isAuthenticated: result.isAuthenticated,
            registration: result.registration,
          });
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            loading: false,
            error,
          });
        },
      );
  }

  removeAttendeeSession({ orgId, eventId }) {
    const url = `/organizations/${orgId}/events/${eventId}/authenticate.json`;

    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    };

    fetch(url, options)
      .then(
        (res) => {
          if (res.status !== 200) {
            window.Rollbar.error(`Failed to request ${url}: ${res}`);
          } else {
            window.location.reload();
          }
        },
      );
  }

  render() {
    const { children } = this.props;
    const { removeAttendeeSession } = this;
    const {
      heroSection, event, headerMenu, isAuthenticated, registration, loading, error,
    } = this.state;

    return children({
      headerData: {
        heroSection, event, headerMenu, registration, isAuthenticated,
      },
      loading,
      error,
      removeAttendeeSession,
    });
  }
}

EventHeaderSectionContainer.propTypes = {
  children: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
};

export default EventHeaderSectionContainer;
