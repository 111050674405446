import React from 'react';
import PropTypes from 'prop-types';
import LogoBlock from './PropertyCard/LogoBlock';
import AddressBlock from './PropertyCard/AddressBlock';
import PriceBlock from './PropertyCard/PriceBlock/PriceBlock';

function MarkerPropertyInfo({
  property,
  showRates,
  property: {
    name,
    address,
    distanceText,
    distanceDurationText,
    rating,
    totalGuestRooms,
    meetingRooms,
    image,
    displayRate,
    displayCurrency,
    isNegotiated,
    propertyResultInfoId,
    fallbackImage,
  },
  hasPropertiesAvailability,
  handleAddBidRequest,
  handleBookNow,
  handleRemoveBid,
  canBookNow,
  canEbid,
  isAdded,
}) {
  return (
    <div className="bid_marker_info">
      <div className="tw-flex">
        <LogoBlock image={image} fallbackImage={fallbackImage} />
        <AddressBlock
          name={name}
          address={address}
          distanceText={distanceText}
          distanceDurationText={distanceDurationText}
          rating={rating}
          totalGuestRooms={totalGuestRooms}
          meetingRooms={meetingRooms}
          locationAddress="event"
        />
      </div>

      <PriceBlock
        property={property}
        showRates={showRates}
        handleAddBidRequest={handleAddBidRequest}
        handleBookNow={handleBookNow}
        handleRemoveBid={handleRemoveBid}
        canBookNow={canBookNow}
        canEbid={canEbid}
        isAdded={isAdded || false}
        hasPropertiesAvailability={hasPropertiesAvailability}
        displayRate={displayRate}
        displayCurrency={displayCurrency}
        isNegotiated={isNegotiated || false}
        propertyResultInfoId={propertyResultInfoId}
      />
    </div>
  );
}
MarkerPropertyInfo.defaultProps = {
  showRates: true,
};

MarkerPropertyInfo.propTypes = {
  showRates: PropTypes.bool,
  property: PropTypes.shape().isRequired,
};

export default MarkerPropertyInfo;
